import { environment } from './../../environments/environment';
import { CommonService } from './../common.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  PRODUCT = 'inventory/product';

  constructor(private cos: CommonService) { }

  getProduct(obj) {
    return new Promise((resolve, reject) => {

      this.cos.httpRequest(`${this.PRODUCT}?search=` + obj.search + `&page=${obj.pageNumber}&limit=${obj.noOfRecord}`)
        .then(resolve)
        .catch(reject);
    });
  }

  postProduct(data) {
    return new Promise((resolve, reject) => {
      this.cos.httpRequest(this.PRODUCT, data, 'POST')
        .then(resolve)
        .catch(reject);
    });
  }

  putProduct(data) {
    return new Promise((resolve, reject) => {
      this.cos.httpRequest(this.PRODUCT, data, 'PUT')
        .then(resolve)
        .catch(reject);
    });
  }

  delete(data) {
    return new Promise((resolve, reject) => {
      this.cos.httpRequest(this.PRODUCT, data, 'DELETE')
        .then(resolve)
        .catch(reject);
    });
  }

  uploadImage(data) {
    return new Promise((resolve, reject) => {
      this.cos.httpFormRequest(environment.UPLOAD, data)
        .then(resolve)
        .catch(reject);
    });
  }

  getServiceByProduct(id){
    return new Promise((resolve, reject) => {
      this.cos.httpRequest(this.PRODUCT+'/getServicesProduct?productId='+id)
        .then(resolve)
        .catch(reject);
    });
  }
}
