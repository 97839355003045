import { CommonService } from './../common.service';
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent implements OnInit {

  RESET_PASSWORD = 'api/user/resetPasswordLink';
  showSuccess = false;

  constructor(private cos: CommonService) { }

  email = new FormControl('', [Validators.required, Validators.email]);

  ngOnInit() {
  }

  resetPassword() {
    this.cos.httpRequest(`${this.RESET_PASSWORD}?email=${this.email.value}&role=ADMIN`)
      .then((res: ResetPassRes) => {

        if (res.status) {
          this.cos.openSnackBar({ message: res.message });
          this.showSuccess = true;
        } else {
          this.cos.openSnackBar({ message: res.message });
        }

      })
      .catch(err => this.cos.openSnackBar({ message: err.message }));
  }
}

export interface ResetPassRes {
  data: null;
  message: string;
  status: boolean;
}
