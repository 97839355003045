import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { CommonService } from './../common.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  LOGIN_URL = 'api/auth/login';
  data = {
    contact: { email: '', phone: '' },
    userName: 'User',
    picture: 'assets/images/default.jpg',
    _id: ''
  };

  getAdminDetail = new BehaviorSubject(this.data);

  constructor(private cos: CommonService, private router: Router) { }

  login(data) {
    this.cos.httpRequest(this.LOGIN_URL, data, 'POST', false)
      .then((res: any) => {
        if (res.status) {
          this.cos.openSnackBar({ message: res.message });
          this.data = { ...this.data, ...res.data };
          localStorage.setItem('observable', btoa(JSON.stringify(this.data)));
          this.updateAdminDetail(this.data);
          localStorage.setItem('@dminT0ken', res.data.token);
          this.router.navigateByUrl('/dashboard');
        } else {
          this.cos.openSnackBar({ message: res.message });
        }
      })
      .catch(err => this.cos.openSnackBar({ message: err.message }));
  }

  public updateAdminDetail(data): any {
    this.getAdminDetail.next(data);
  }

  public logout() {
    this.getAdminDetail.next(this.data);
    localStorage.clear();
  }

}

export interface LoginRes {
  data: { token: string, admin: {} };
  message: string;
  status: boolean;
}
