import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
// import {CustomerService} from '../services/customer.service';
import { SelectionModel } from '@angular/cdk/collections';
import { FaqService } from './collectiondelivery.service'
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { CommonService } from 'src/app/common.service';
declare var $: any;

@Component({
  selector: 'app-collectiondelivery',
  templateUrl: './collectiondelivery.component.html',
  styleUrls: ['./collectiondelivery.component.css']
})
export class CollectiondeliveryComponent implements OnInit {
  constructor(private httpService: HttpClient, private faqService: FaqService, private formbuilder: FormBuilder, private cos: CommonService) { }
  //arrBirds: string [];    //get data
  questionData: any = [];
  questionForm: FormGroup
  errorMessage: any = ""
  hide = true;
  displayedColumns: string[] = ['question', 'answer', 'action'];
  dataSource = new MatTableDataSource<any>();
  selection = new SelectionModel<PeriodicElement>(true, []);
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  fltrtoggle() {

    $('#fltrdown').toggle();

  }

  ngOnInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.getQuestions();
    this.createForm();
  }

  createForm() {
    this.questionForm = this.formbuilder.group({
      id: new FormControl(''),
      question: new FormControl('', [Validators.required]),
      answer: new FormControl('', [Validators.required])
    })
  }

  getQuestions() {
    this.faqService.getFaq().then(result => {
      this.dataSource.data = result as [];
    })
  }

  patchValue(data) {
    this.questionForm.patchValue({
      id: data._id,
      question: data.question,
      answer: data.answer
    })
  }

  addQuestions() {
    const data = this.questionForm.value;
    delete data.id;
    if (!data.question || !data.answer)
      this.errorMessage = 'Please fill all required fields'
    this.faqService.addFaq(data)
    this.getQuestions()
  }

  editQuestions() {
    if (!this.questionForm.value.question || !this.questionForm.value.answer)
      this.errorMessage = 'Please fill all required fields'
    this.faqService.editFaq(this.questionForm.value)
    this.getQuestions()
  }

  deleteQuestions(id) {
    this.faqService.deleteFaq(id)
    this.getQuestions()
  }

  onSelect(item: any) {
    // alert();
   // You get the Id of the selected item here
  }
}
export interface PeriodicElement {
  question: string;
  answer: string;
  status: boolean;
  action: string;
}
const ELEMENT_DATA: PeriodicElement[] = [
  { question: 'Can I give you my items with my hangers or bag?', answer: 'Unfortunately, it would be too hard for us to track which hangers belong to which customers, so please give us your items without any personal hangers or bags.', status: true, action: '' }

];



