import { Component, OnInit,ViewChild } from '@angular/core';
import {SelectionModel} from '@angular/cdk/collections';
import {MatPaginator,MatSort, MatTableDataSource} from '@angular/material';

import { HttpErrorResponse,HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {
  constructor(private httpService:HttpClient) { }
  displayedColumns: string[] = ['select','title', 'date', 'from','method', 'to','action'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);
  selection = new SelectionModel<PeriodicElement>(true, []);
  template_Hide: boolean = true;
  notif_Hide: boolean = false;
  isNotif: boolean = true;
  isTemp: boolean = false;

  notifOrTemp(value){
    if(value == '1') { 

     this.template_Hide = true,
     this.notif_Hide = false
   } else if(value == '2') { 

     this.notif_Hide = true,
     this.template_Hide = false
   }
   }
   /** Whether the number of selected elements matches the total number of rows. */
   isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: PeriodicElement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.title + 1}`;
  }

applyFilter(filterValue: string) {
  this.dataSource.filter = filterValue.trim().toLowerCase();
  if (this.dataSource.paginator) {
    this.dataSource.paginator.firstPage();
  }
}
@ViewChild(MatSort,{static:false}) sort: MatSort;
@ViewChild(MatPaginator,{static:false}) paginator: MatPaginator;

ngOnInit() { 
  this.dataSource.sort = this.sort;
  this.dataSource.paginator = this.paginator;

}
 
}
export interface PeriodicElement {
  title: string;
  date:string,
  method:string,
  from:string,
  to: string;   
  action:string;
}
const ELEMENT_DATA: PeriodicElement[] = [
{title:"dummy", date :"3-2-19", from:"mohali",to:"chandigarh",method:'SMS',action:''}

];
