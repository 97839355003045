import { LoginService } from './../login/login.service';
import { FormBuilder, Validators } from '@angular/forms';
import { PeriodicElement, PaginationRes, DefaultRes } from './../users/users.component';
import { UserService } from './../users/user.service';
import { CommonService } from './../common.service';
import { ProfileService } from './profile.service';
import { Component, OnInit, ViewChild, OnChanges, OnDestroy, Input } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, PageEvent } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
declare var $: any;
const ELEMENT_DATA: PeriodicElement[] = [];

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit, OnDestroy {
  /** Whether the number of selected elements matches the total number of rows. */

  modalType = 'Add Admin';

  adminDetail = {
    contact: { email: '', phone: '' },
    userName: 'User',
    picture: 'assets/images/default.jpg',
    _id: ''
  };

  adminObservable: any;

  constructor(
    private loginSrvc: LoginService,
    private spinner: NgxSpinnerService,
    private cos: CommonService,
    private profileSrvc: ProfileService,
    private usrSrvc: UserService,
    private fb: FormBuilder) {
    this.adminObservable = this.loginSrvc.getAdminDetail.subscribe((res) => {
      this.adminDetail = { ...this.adminDetail, ...res };
    });
  }

  customerdata: any;
  deleteIDs = new Array();
  displayedColumns: string[] = ['select', 'name', 'email', 'phoneNumber', 'image', 'action'];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  selection = new SelectionModel<PeriodicElement>(true, []);
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  @ViewChild('uploadImage', { static: false }) uploadImage;
  @Input() diameter = 150;
  @Input() strokeWidth = 5;

  showSpinner = false;

  profileForm = this.fb.group({
    id: [''],
    name: ['', [Validators.required]],
    email: ['', [Validators.required]],
    phone: ['', [Validators.required, Validators.minLength(7), Validators.maxLength(12)]],
    password: [''],
    picture: ['', Validators.required]
  });

  pager = {
    totalItems: 0,
    currentPage: 1,
    totalPages: 10,
    startPage: 1,
    pageSize: 10,
    endPage: 10,
    startIndex: 1,
    endIndex: 10,
    pages: []
  };

  searchObj = {
    type: 'ADMIN',
    search: '',
    pageNumber: 1,
    noOfRecord: 10
  };

  profilePic: any;

  length = 100;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  typing: any = null;

  pageEvent: PageEvent;

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }
  applyFilter(filterValue: string) {
    const value = filterValue.trim().toLowerCase();
    clearInterval(this.typing);
    this.searchObj.search = value;
    this.typing = setTimeout(() => this.getAdmins(), 500);
  }
  fltrtoggle() {
    $('#fltrdown').toggle();
  }

  ngOnInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.getAdmins();
  }

  resetForm() {
    this.modalType = 'Add Admin';
    this.profileForm.reset();
  }

  uploadProfileImg() {
    this.showSpinner = true;
    const fi = this.uploadImage.nativeElement;
    const imageData = new FormData();

    if (fi.files && fi.files[0] && fi.files[0].type.includes('image')) {

      imageData.append('file', fi.files[0]); // data to upload ...

      const reader = new FileReader(); // data to display ...
      reader.readAsDataURL(fi.files[0]);
      reader.onload = () => {
        this.profilePic = reader.result;
      };
    } else {
      this.cos.openSnackBar({ message: 'Only Type Images can be uploaded' });
      this.showSpinner = false;
    }

    this.profileSrvc.uploadImage(imageData)
      .then((res: Upload) => {
        if (!res.status) { return; }
        this.profileForm.patchValue({
          picture: res.data
        });
      })
      .then(() => this.showSpinner = false)
      .catch();
  }

  getAdmins() {
    this.spinner.show();

    this.usrSrvc.getUser(this.searchObj)
      .then((res: PaginationRes) => {
        if (res.status) {
          this.dataSource.data = res.data.filter(elem => elem._id !== this.adminDetail._id);
          this.pager = this.cos.getPager(Number(res.totalRecords), Number(res.currentPage));
          this.length = this.pager.totalItems;
          this.pageSize = this.pager.pageSize;
        } else {
          this.cos.openSnackBar({ message: res.message });
        }
      })
      .then(() => this.spinner.hide())
      .catch(err => {
        this.spinner.hide();
        this.cos.openSnackBar({ message: err.message });
      });
  }

  setPage(page: PageEvent): PageEvent {
    this.searchObj.pageNumber = Number(page.pageIndex + 1);
    this.searchObj.noOfRecord = Number(page.pageSize);
    this.getAdmins();
    return;
  }

  async bulkDelete() {
    const userIds = await this.selection.selected.map(selec => selec._id);
    if (userIds.length === 0) {
      this.cos.openSnackBar({ message: 'Please Select admin to delete !' });
      return;
    }
    this.deleteIDs = userIds;
    this.deleteUser();
  }

  async singleDelete(ids) {
    await this.deleteIDs.push(ids);
    this.deleteUser();
  }

  deleteUser() {

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.usrSrvc.delete({ ids: this.deleteIDs })
          .then((res: DefaultRes) => {
            this.cos.openSnackBar({ message: res.message });
            this.dataSource.data = this.dataSource.data.filter(obj => !new Set(this.deleteIDs).has(obj._id));
            this.cos.debugMail(res);
          })
          .catch(err => {
            this.cos.openSnackBar({ message: err.message });
          });
      }
    });

  }

  addProfile() {

    const data = {
      picture: this.profileForm.value.picture,
      name: {
        userName: this.profileForm.value.name
      },
      phone: this.profileForm.value.phone,
      auth: {
        email: this.profileForm.value.email,
        password: this.cos.genPassword(8),
      }
    };

    this.profileSrvc.postAdmin(data)
      .then((res: DefaultRes) => {
        if (res.status) {
          this.cos.openSnackBar({ message: res.message });
          this.resetForm();
          $('#addadmin').modal('hide');
          this.getAdmins();
        } else {
          this.cos.openSnackBar({ message: res.message });
        }
      })
      .catch(err => {
        this.cos.openSnackBar({ message: err.message });
      });
  }

  editProfile() {
    if (this.profileForm.invalid) { return; }

    const data = {
      picture: this.profileForm.value.picture,
      name: {
        userName: this.profileForm.value.name
      },
      contact: {
        email: this.profileForm.value.email,
        phone: this.profileForm.value.phone
      },
      userId: this.profileForm.value.id,
    };

    this.usrSrvc.putUser(data)
      .then((res: DefaultRes) => {
        if (res.status) {
          this.cos.openSnackBar({ message: res.message });
          this.getAdmins();
          this.resetForm();
          $('#addadmin').modal('hide');
        } else {
          this.cos.openSnackBar({ message: res.message });
        }
      })
      .catch(err => {
        this.cos.openSnackBar({ message: err.message });
      });

  }

  submitAdmin() {
    if (this.profileForm.value.id) {
      this.editProfile();
    } else {
      this.addProfile();
    }
  }

  setValueToModal(element) {
    this.modalType = 'Edit Admin';
    this.profileForm.patchValue({
      id: element._id,
      name: element.name.userName,
      email: element.contact.email,
      phone: element.contact.phone,
      picture: element.picture,
    });
  }

  ngOnDestroy(): void {
    this.adminObservable.unsubscribe();
  }
}

export interface Upload {
  data: string;
  message: string;
  status: boolean;
}
