import { NgxSpinnerService } from 'ngx-spinner';
import { CategoriesService } from './../categories/categories.service';
import { CommonService } from './../common.service';
import { FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, PageEvent } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { ProductService } from './product.service';
import { ServicesService } from '../services/services.service'
import Swal from 'sweetalert2';
declare var $: any;
const ELEMENT_DATA: PeriodicElement[] = [];

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {
  public fieldArray: Array<any> = [{
    "_service": "",
    "price": {
      "totalAmount": "",
      "units": "AUD"
    }
  }];
  private newAttribute: any = {
    "_service": "",
    "price": {
      "totalAmount": "",
      "units": "AUD"
    }
  };
  constructor(
    private fb: FormBuilder,
    private cos: CommonService,
    private productSrvc: ProductService,
    private spinner: NgxSpinnerService,
    private catSrvc: CategoriesService,
    private servService: ServicesService) {
    this.getCategory();
  }

  modalType = 'Add Product';
  deleteIDs = new Array();
  allItems: any[];
  pServices : any = []
  pagedItems: any[] = [];
  @Input() diameter = 150;
  @Input() strokeWidth = 5;

  showSpinner = false;
  pager = {
    totalItems: 0,
    currentPage: 1,
    pageSize: 10,
    totalPages: 10,
    startPage: 1,
    endPage: 10,
    startIndex: 1,
    endIndex: 10,
    pages: []
  };
  searchObj = {
    search: '',
    pageNumber: 1,
    noOfRecord: 10
  };
  rightModalData = {
    productDetail: {} as PeriodicElement
  };

  categoryImage: any;
  validForm: boolean;

  displayedColumns: string[] = ['select', 'name', 'image', 'category', 'action'];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  selection = new SelectionModel<PeriodicElement>(true, []);
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild('uploadImage', { static: false }) uploadImage;

  productForm = this.fb.group({
    productId: [''],
    productName: ['', [Validators.required]],
    categoryId: ['', [Validators.required]],
    service: [[]],
    image: [''],
  });
  categories: [] = [];
  services: any = []
  typing: any = null;

  length = 100;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25, 100];

  pageEvent: PageEvent;
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }
  applyFilter(filterValue: string) {
    const value = filterValue.trim().toLowerCase();
    clearInterval(this.typing);
    this.searchObj.search = value;
    this.searchObj.pageNumber = 1;
    this.typing = setTimeout(() => this.getProduct(), 500);
  }
  fltrtoggle() {
    $('#fltrdown').toggle();
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
  }

  ngOnInit() {
    this.dataSource.sort = this.sort;
    this.getProduct();
    this.getService()
  }

  resetForm() {
    this.modalType = 'Add Product';
    this.productForm.reset();
    this.validForm = false;
  }

  getCategory() {

    this.catSrvc.getCategory({ ...this.searchObj, ...{ type: 'ALL' } })
      .then((res) => {
        if (res['status']) {
          this.categories = res['data'];
        }
      })
      .catch();
  }

  getService() {
    this.servService.getService({ search: "", pageNumber: 1, noOfRecord: 999999999 })
      .then((res) => {
        if (res['status']) {

          this.services = res['data'];
        }
      })
      .catch();
  }

  getProduct() {
    this.spinner.show();
    this.productSrvc.getProduct(this.searchObj)
      .then((res: PaginationRes) => {

        if (!res.status) {
          this.cos.openSnackBar({ message: res.message });
          this.dataSource.data = res.data;
          this.pager = this.cos.getPager(Number(res.totalRecords), Number(res.currentPage));
          this.length = this.pager.totalItems;
          this.pageSize = this.pager.pageSize;
        } else {
          this.dataSource.data = res.data;
          this.pager = this.cos.getPager(Number(res.totalRecords), Number(res.currentPage));
          this.length = this.pager.totalItems;
          this.pageSize = this.pager.pageSize;

          for (let x = 0; x < this.dataSource.data.length; x++) {
            for (let y = 0; y < this.categories.length; y++) {
              if (this.dataSource.data[x]['_category'] === this.categories[y]['_id']) {
                this.dataSource.data[x]['categoryName'] = this.categories[y]['categoryName'];
              }
            }
          }
        }
      })
      .then(() => this.spinner.hide())
      .catch(err => {
        this.spinner.hide();
        this.cos.openSnackBar({ message: err.message });
      });
  }


  setPage(page: PageEvent): PageEvent {
    this.searchObj.pageNumber = Number(page.pageIndex + 1);
    this.searchObj.noOfRecord = Number(page.pageSize);
    this.getProduct();
    return;
  }

  imgError(event) {
    event.target.src = 'assets/images/logo.png';
  }

  submitProduct() {
    if (this.productForm.value.productId) {
      this.editProduct();
    } else {
      this.addProduct();
    }
  }

  uploadProfileImg() {
    this.showSpinner = true;
    const fi = this.uploadImage.nativeElement;
    const imageData = new FormData();
    this.validForm = false;
    if (fi.files && fi.files[0] && fi.files[0].type.includes('image')) {

      imageData.append('file', fi.files[0]); // data to upload ...
      const reader = new FileReader(); // data to display ...
      reader.readAsDataURL(fi.files[0]);
      reader.onload = () => {
        this.categoryImage = reader.result;
      };
    } else {
      this.cos.openSnackBar({ message: 'Only Type Images can be uploaded' });
      this.showSpinner = false;
    }

    this.productSrvc.uploadImage(imageData)
      .then((res: Upload) => {
        if (!res.status) { return; }
        this.productForm.patchValue({
          image: res.data
        });
        this.validForm = true;
      })
      .then(() => this.showSpinner = false)
      .catch();
  }

  addProduct() {
    if (this.productForm.invalid) { return; }

    delete this.productForm.value.productId;

    this.productSrvc.postProduct(this.productForm.value)
      .then((res: DefaultRes) => {
        if (res.status) {
          this.cos.openSnackBar({ message: res.message });
          this.resetForm();
          this.fieldArray = [{
            "_service": "",
            "price": {
              "totalAmount": "",
              "units": "AUD"
            }
          }]
          $('#addproduct').modal('hide');
          this.getProduct();
        } else {
          this.cos.openSnackBar({ message: res.message });
        }
      })
      .catch(err => {
        this.cos.openSnackBar({ message: err.message });
      });
  }

  editProduct() {
    if (this.productForm.invalid) { return; }

    this.productSrvc.putProduct(this.cos.deleteProp(['categoryId'], this.productForm.value))
      .then((res: DefaultRes) => {
        if (res.status) {
          this.cos.openSnackBar({ message: res.message });
          this.getProduct();
          this.resetForm();
          $('#addproduct').modal('hide');
        } else {
          this.cos.openSnackBar({ message: res.message });
        }
      })
      .catch(err => {
        this.cos.openSnackBar({ message: err.message });
      });
  }

  async bulkDelete() {
    const userIds = await this.selection.selected.map(selec => selec._id);
    if (userIds.length === 0) {
      this.cos.openSnackBar({ message: 'Please Select garment to delete !' });
      return;
    }
    this.deleteIDs = userIds;
    this.deleteUser();
  }

  async singleDelete(ids) {
    await this.deleteIDs.push(ids);
    this.deleteUser();
  }

  addFieldValue() {
    this.newAttribute = {
      "_service": "",
      "price": {
        "totalAmount": "",
        "units": "AUD"
      },
      "ETA": 1
    };
    this.fieldArray.push(this.newAttribute)
  }

  updateService(index, id) {
    let serviceObj = this.services.find(service => {  return service._id == id })
    this.fieldArray[index]._service = id
    this.fieldArray[index].ETA = serviceObj ? serviceObj.ETA : 1
    this.productForm.patchValue({
      service: this.fieldArray
    });
  }

  updatePrice(index, price) {
    this.fieldArray[index].price.totalAmount = price
    this.productForm.patchValue({
      service: this.fieldArray
    });
  }


  deleteFieldValue(index) {
    this.fieldArray.splice(index, 1);
  }


  deleteUser() {

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.productSrvc.delete({ ids: this.deleteIDs })
          .then((res: DefaultRes) => {
            this.cos.openSnackBar({ message: res.message });
            this.dataSource.data = this.dataSource.data.filter(obj => !new Set(this.deleteIDs).has(obj._id));
          })
          .catch(err => {
            this.cos.openSnackBar({ message: err.message });
          });
      }
    });

  }

  rightmodal(data: PeriodicElement) {
    this.rightModalData.productDetail = data;
  }

  getServiceByProduct(id) {
    this.productSrvc.getServiceByProduct(id)
      .then((res) => {
        if (res['status']) {
          this.pServices = res['data']['service'];
        }
      })
      .catch();
  }

  setValueToModal(elem) {
    this.modalType = 'Edit Product';
    this.productForm.patchValue({
      productId: elem._id,
      productName: elem.productName,
      categoryId: elem._category,
      service: elem.service,
      image: elem.image
    });
    this.fieldArray = elem.service
    elem.image ? this.validForm = true : this.validForm = false;
  }

}
export interface PeriodicElement {
  isDeleted: boolean;
  _id: string;
  productName: string;
  image: string;
  _category: string;
  createdAt: Date;
  updatedAt: Date;
  categoryId: string;
}

export interface PaginationRes {
  currentPage: number;
  data: [PeriodicElement];
  message: string;
  status: boolean;
  totalPage: number;
  totalRecords: number;
}

export interface DefaultRes {
  data: null | PeriodicElement;
  message: string;
  status: boolean;
}

export interface Upload {
  data: string;
  message: string;
  status: boolean;
}
