import { environment } from './../../environments/environment';
import { CommonService } from './../common.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  POST_ADMIN = 'api/user/register/admin';
  constructor(private cos: CommonService) { }

  postAdmin(data) {
    return new Promise((resolve, reject) => {
      this.cos.httpRequest(this.POST_ADMIN, data, 'POST')
        .then(resolve)
        .catch(reject);
    });
  }

  uploadImage(data) {
    return new Promise((resolve, reject) => {
      this.cos.httpFormRequest(environment.UPLOAD, data)
        .then(resolve)
        .catch(reject);
    });
  }

}
