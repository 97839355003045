import { Component, OnInit } from '@angular/core';
import { AddressService } from './address.service';
import { CommonService } from '../common.service';
import { add } from 'ngx-bootstrap/chronos/public_api';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.css']
})
export class AddressComponent implements OnInit {

  mapObj = {
    lat: 0,
    lng: 0
  };

  selectedAddress: any = {};

  constructor(private addressSrvc: AddressService, private cos: CommonService) { }

  ngOnInit() {
    this.getShopAddress();
  }

  getShopAddress() {

    this.addressSrvc.getAddress()
      .then((res: any) => {
        const address = res.data.address;

        if (address.length === 0) {
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
              this.mapObj = {
                lat: +(position.coords.latitude),
                lng: +(position.coords.longitude)
              };
              this.markerDragEnd({
                coords: {
                  lat: +(position.coords.latitude),
                  lng: +(position.coords.longitude)
                }
              });
            });
          } else {
            this.cos.openSnackBar({ message: 'Geolocation is not supported by this browser.' });
          }
        } else {
          this.mapObj.lat = address[0].geo[1];
          this.mapObj.lng = address[0].geo[0];
          this.selectedAddress = address[0];
        }
      })
      .catch();
  }

  getAddress(event) {
    this.selectedAddress = event;
    this.mapObj.lat = event.geo[0];
    this.mapObj.lng = event.geo[1];
  }

  markerDragEnd(event) {

    this.mapObj = {
      lat: event.coords.lat,
      lng: event.coords.lng
    };
    this.cos.getLocWithGeo(this.mapObj)
      .then((res: any) => {

        const address = res.results[0];
        // tslint:disable-next-line: forin
        for (const i in address.address_components) {
          const item = address.address_components[i];
          if (item.types.includes('locality')) {
            this.selectedAddress.locality = item.long_name;
          } else if (item.types.includes('route')) {
            this.selectedAddress.streetAddress = item.long_name;
          } else if (item.types.includes('country')) {
            this.selectedAddress.country = item.long_name;
            this.selectedAddress.region = item.long_name;
          } else if (item.types.includes('postal_code')) {
            this.selectedAddress.postalCode = item.short_name;
          }
        }
        this.selectedAddress.geo = [address.geometry.location.lat, address.geometry.location.lng];
        this.selectedAddress.formattedAddress = address.formatted_address;
      });

  }


  submitAddress() {
    if (Object.entries(this.selectedAddress).length === 0 && this.selectedAddress.constructor === Object) {
      this.cos.openSnackBar({ message: 'Please Select Address !' });
      return;
    }
    const address = {
      streetAddress: this.selectedAddress.route ? this.selectedAddress.route : this.selectedAddress.formattedAddress,
      locality: this.selectedAddress.locality ? this.selectedAddress.locality : 'undefined',
      subLocality: this.selectedAddress.sub_locality ? this.selectedAddress.sub_locality : 'undefined',
      region: this.selectedAddress.country,
      country: this.selectedAddress.country,
      postalCode: this.selectedAddress.postal_code ? this.selectedAddress.postal_code : 'undefined',
      geo: [this.selectedAddress.geo[1], this.selectedAddress.geo[0]],
      formattedAddress: this.selectedAddress.formattedAddress,
      landmark: this.selectedAddress.formattedAddress
    };


    this.addressSrvc.postAddress({ address: [address] })
      .then((res: any) => {
        this.cos.openSnackBar({ message: res.message });
      })
      .catch(err => {
        this.cos.openSnackBar({ message: err.message });
      });
  }

}
