import { environment } from './../environments/environment';
import { Injectable } from '@angular/core';
import { MatSnackBarConfig, MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  snakBarConfig: any = {
    message: 'Hi This is message',
    autoHide: 2000,
    verticalPosition: 'top',
    horizontalPosition: 'right',
    actionButtonLabel: 'Retry'
  };
  setAutoHide = true;
  action = false;

  constructor(public snackBar: MatSnackBar, public router: Router) { }

  httpRequest(url: string, body: any = null, method: string = 'GET', authenticated: boolean = true) {

    return new Promise((resolve, reject) => {

      if (!navigator.onLine) {
        this.openSnackBar({ message: 'Please connect to internet first !' });
        return reject;
      }

      fetch(environment.BASE_URL + url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          Authorization: authenticated
            ? `Bearer ${localStorage.getItem('@dminT0ken')}`
            : undefined
        },
        body: body
          ? JSON.stringify(body)
          : undefined
      })
        .then(response => {
          if (response.status === 401) {
            if (authenticated) {
              this.openSnackBar({ message: 'You are not Authorised' });
              localStorage.clear();
              return this.router.navigate(['/']);
            } else {
              return response.json();
            }
          } else {
            return response.json();
          }
        })
        .then(resolve)
        .catch(reject);
    });
  }

  httpFormRequest(url: string, body: any = null, method: string = 'POST', authenticated: boolean = true) {
    return new Promise((resolve, reject) => {

      if (!navigator.onLine) {
        this.openSnackBar({ message: 'Please connect to internet first !' });
        return reject;
      }

      fetch(url, {
        method,
        headers: {
          Authorization: authenticated
            ? `Bearer ${localStorage.getItem('@dminT0ken')}`
            : undefined
        },
        body: body
          ? body
          : undefined
      })
        .then(response => response.json())
        .then(resolve)
        .catch(reject);
    });
  }

  getPager(totalItems: number, currentPage: number = 1, pageSize: number = 10) {
    if (!navigator.onLine) {
      this.openSnackBar({ message: 'Please connect to internet' });
      return;
    }
    // calculate total pages
    const totalPages = Math.ceil(totalItems / pageSize);

    // ensure current page isn't out of range
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    let startPage: number;
    let endPage: number;
    if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }

    // calculate start and end item indexes
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    const pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

    // return object with all pager properties required by the view
    return {
      totalItems,
      currentPage,
      pageSize,
      totalPages,
      startPage,
      endPage,
      startIndex,
      endIndex,
      pages
    };
  }

  openSnackBar(snackbar: any = null) {
    snackbar = { ...this.snakBarConfig, ...snackbar };

    const config = new MatSnackBarConfig();
    config.verticalPosition = snackbar.verticalPosition;
    config.horizontalPosition = snackbar.horizontalPosition;
    config.duration = this.setAutoHide ? snackbar.autoHide : 0;
    // config.panelClass = ['snackBar-color']; // scss define in style.css
    this.snackBar.open(snackbar.message, this.action ? snackbar.actionButtonLabel : undefined, config);
  }

  renameKeys = (keysMap, obj) =>
    Object.keys(obj).reduce(
      (acc, key) => ({
        ...acc,
        ...{ [keysMap[key] || key]: obj[key] }
      }),
      {}
    )

  deleteProp = (delProp, bundle) => {
    try {
      const data = JSON.parse(JSON.stringify(bundle));

      Object.keys(data).forEach(arr => {
        if (Array.isArray(data[arr])) {
          for (const property in data[arr]) {
            if (data[arr].hasOwnProperty(property) === '_id') {
              delete data[arr][property];
            }
          }
        }
        if (typeof (data[arr]) === 'object') {
          delProp.forEach(prop => {
            delete data[arr][prop];
          });
          Object.keys(data[arr]).forEach(x => {
            if (typeof (data[arr][x]) === 'object') {
              delProp.forEach(prop => {
                delete data[arr][x][prop];
              });
            }
          });
        }
      });

      delProp.forEach(prop => {
        delete data[prop];
      });
      return data;
    } catch (err) {
      return bundle;
    }
  }

  genPassword = (lngth) => {
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890,./;\'[]\=-)(*&^%$#@!~`';
    let password = '';
    for (let i = 0; i < lngth; i++) {
      password += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return password;
  }

  getNearestLocation(lat, lng, method: string = 'GET') {
    return new Promise((resolve, reject) => {
      fetch(`https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=${lng},${lat}&radius=1500&key=${environment.GOOGLE_KEY}`, {
        method,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(resolve)
        .catch(reject);
    });
  }

  debugMail = (err) => {
    // if (environment.production) { return; }
    // const body = {
    //   to: [
    //     environment.devMail
    //   ],
    //   subject: 'Bro you Got a damn bug !!',
    //   html: `<p>${err.message}</p>`,
    // };
    // this.httpRequest(`notifications/email`, body, 'POST')
    //   .then(console.log)
    //   .catch(console.log);
  }

  getLocWithGeo(data) {
    return new Promise((resolve, reject) => {
      fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${data.lat},${data.lng}&key=${environment.GOOGLE_KEY}`)
        .then(res => res.json())
        .then(resolve)
        .catch(reject);
    });
  }

}
