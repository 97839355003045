import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortId'
})
export class ShortIdPipe implements PipeTransform {

  transform(value: any, args?: any): any {

    if (!value) { return ''; }
    return `...${value.substring(value.length - 3, value.length)}`;
  }

}
