import { FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { CommonService } from './../common.service';
import { Component, OnInit } from '@angular/core';
import { ResetPasswordService } from './reset-password.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  constructor(
    private resetPassSrvc: ResetPasswordService,
    private cos: CommonService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router) {
    this.route.queryParams.subscribe(params => {
      try {
        this.passToken = params.tok;
      } catch (err) {
        this.cos.debugMail(err);
      }
    });
  }

  passToken = '';

  changePasswordForm = this.fb.group({
    password: ['', Validators.required],
    confirmPassword: ['', Validators.required]
  });

  ngOnInit() { }

  resetPassword() {

    if (this.changePasswordForm.value.password !== this.changePasswordForm.value.confirmPassword) {
      this.cos.openSnackBar({ message: 'New password and Confirm Password dosenot match !' });
      return;
    }

    const data = {
      token: this.passToken,
      password: this.changePasswordForm.value.confirmPassword
    };

    this.resetPassSrvc.resetPassword(data)
      .then((res: DefaultRes) => {


        // if (res.status) {
        //   this.cos.openSnackBar({ message: res.message });
        //   this.router.navigate(['/']);
        // } else {
        //   this.cos.openSnackBar({ message: res.message });
        //   this.cos.debugMail(res);
        // }
      })
      .catch(err => {
        this.cos.openSnackBar({ message: err.message });
        this.cos.debugMail(err);
      });
  }
}

export interface DefaultRes {
  data: null;
  message: string;
  status: boolean;
}
