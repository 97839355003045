import { Component, OnInit,ViewChild } from '@angular/core'; 
import {MatPaginator,MatSort, MatTableDataSource} from '@angular/material';
import { HttpErrorResponse,HttpClient } from '@angular/common/http'; 
// import {CustomerService} from '../services/customer.service';
import {SelectionModel} from '@angular/cdk/collections';
declare var $ :any;

@Component({
  selector: 'app-testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.css']
})
export class TestimonialComponent implements OnInit {
  constructor(private httpService: HttpClient) { }
  //arrBirds: string [];    //get data
  customerdata:any; 
  hide = true;
  displayedColumns: string[] = ['select','name', 'designation', 'company', 'rating','description','image','status','action'];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  selection = new SelectionModel<PeriodicElement>(true, []);
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  @ViewChild(MatSort,{static:false}) sort: MatSort;
  @ViewChild(MatPaginator,{static:false}) paginator: MatPaginator;
  fltrtoggle(){
   
    $('#fltrdown').toggle();
 
}

  ngOnInit() { 
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    // this. getdata();
  }  
  //  getdata(){
  //    this.customerservice.getdata().subscribe(data=>{
  //      this.customerdata = data;
  //      this.dataSource.data = this.customerdata;
  //      console.log(data);
  //    })
  //  }

   onSelect(item: any) {
    // alert();
    console.log("Selected item Id: ",item.id); // You get the Id of the selected item here
}
}
export interface PeriodicElement {
  name: string;
  designation:string;
  company:string;
  rating: string;  
  description:string;  
  image:string;
   status:boolean; 
  action:string;
}
const ELEMENT_DATA: PeriodicElement[] = [
  {  name:'Gmerice', designation:'Founder', company:'GG Cleans',rating:'1',description:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis sit amet, consectetur adipiscingnostrud exercitation ullamco',image:'assets/images/default.jpg',status:true,action:''}

];



