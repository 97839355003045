import { Injectable } from '@angular/core';
import { CommonService } from '../common.service';

@Injectable({
  providedIn: 'root'
})
export class PromocodeService {

  PROMO = 'promo/promo';

  constructor(private cos: CommonService) { }

  getPromo(obj) {
    return new Promise((resolve, reject) => {
      this.cos.httpRequest(`${this.PROMO}?search=` + obj.search + `&page=${obj.pageNumber}&limit=${obj.noOfRecord}`)
        .then(resolve)
        .catch(reject);
    });
  }

  postPromo(data) {
    return new Promise((resolve, reject) => {
      this.cos.httpRequest(this.PROMO, data, 'POST')
        .then(resolve)
        .catch(reject);
    });
  }

  putPromo(data) {
    return new Promise((resolve, reject) => {
      this.cos.httpRequest(this.PROMO, data, 'PUT')
        .then(resolve)
        .catch(reject);
    });
  }

  delete(data) {
    return new Promise((resolve, reject) => {
      this.cos.httpRequest(this.PROMO, data, 'DELETE')
        .then(resolve)
        .catch(reject);
    });
  }

  sendPromoToAll(data) {
    return new Promise((resolve, reject) => {
      this.cos.httpRequest(`${this.PROMO}/sendPromoToAll `, data, 'POST')
        .then(resolve)
        .catch(reject);
    });
  }

}
