import { Component, OnInit,ViewChild } from '@angular/core'; 
import {MatPaginator,MatSort, MatTableDataSource} from '@angular/material';
import { HttpErrorResponse,HttpClient } from '@angular/common/http'; 
// import {CustomerService} from '../services/customer.service';
import {SelectionModel} from '@angular/cdk/collections';
declare var $ :any;

@Component({
  selector: 'app-about-us-features',
  templateUrl: './about-us-features.component.html',
  styleUrls: ['./about-us-features.component.css']
})
export class AboutUsFeaturesComponent implements OnInit {
  constructor(private httpService: HttpClient) { }
  //arrBirds: string [];    //get data
  customerdata:any; 
  hide = true;
  displayedColumns: string[] = ['select','title','description','status','action'];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  selection = new SelectionModel<PeriodicElement>(true, []);
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  @ViewChild(MatSort,{static:false}) sort: MatSort;
  @ViewChild(MatPaginator,{static:false}) paginator: MatPaginator;
  fltrtoggle(){
   
    $('#fltrdown').toggle();
 
}

  ngOnInit() { 
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    // this. getdata();
  }  
  //  getdata(){
  //    this.customerservice.getdata().subscribe(data=>{
  //      this.customerdata = data;
  //      this.dataSource.data = this.customerdata;
  //      console.log(data);
  //    })
  //  }

   onSelect(item: any) {
    // alert();
    console.log("Selected item Id: ",item.id); // You get the Id of the selected item here
}
}
export interface PeriodicElement {
  title: string; 
  description:string;  
   status:boolean; 
  action:string;
}
const ELEMENT_DATA: PeriodicElement[] = [
  {  title:'24 Services',description:'WWWhere does it come from? Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.',status:true,action:''}

];



