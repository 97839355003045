import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from './../common.service';
import { Component, OnInit } from '@angular/core';
import { SettingsService } from './settings.service';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  editorConfig = {

    "toolbar": [
      ["bold", "italic",],
      ["fontName", "fontSize", "color"],
      ["justifyLeft", "justifyCenter", "justifyRight", "justifyFull", "indent"],
      ["blockquote", "orderedList", "unorderedList"],
    ]
  };
  componentSchema: ComponentSchema = {
    title: '',
    value: '',
    description: '',
  };

  dataSource: PeriodicElement = {
    name: '',
    address: {
      streetAddress: '',
      locality: '',
      subLocality: '',
      region: '',
      country: '',
      postalCode: '',
      geo: {
        lat: '',
        lng: ''
      },
    },
    contact: [this.componentSchema, this.componentSchema],
    legal: [this.componentSchema, this.componentSchema, this.componentSchema],
    founder: [''],
    foundingDate: new Date(),
    logo: [''],
    links: [this.componentSchema],
  };

  constructor(
    private settingSrvc: SettingsService,
    private cos: CommonService,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder
  ) { }

  charge: any = {}
  chargeValue: any = 0
  type: any = 'PERCENTAGE'
  id: any = ""
  // deliveryCharge : any = 0
  contactForm = this.fb.group({
    name: ['', [Validators.required]],
    email: ['', [Validators.required]],
    phone: ['', [Validators.required]],
    address: ['', [Validators.required]]
  });

  policyForm = this.fb.group({
    value: ['', [Validators.required]]
  });

  termsForm = this.fb.group({
    value: ['', [Validators.required]]
  });

  cancellationForm = this.fb.group({
    value: ['', [Validators.required]]
  });

  ngOnInit() {
    this.getData();
  }

  submitContactForm() {

    const data = {
      name: this.contactForm.value.name,
      address: {
        streetAddress: this.contactForm.value.address
      },
      contact: [
        {
          title: 'PhoneNo',
          value: this.contactForm.value.phone,
          description: 'Office contact number',
          url: 'www.relinns.com'
        },
        {
          title: 'email',
          value: this.contactForm.value.email,
          description: 'Office contact number',
          url: 'www.relinns.com'
        }
      ]

    };

    this.settingSrvc.putSetting(data)
      .then((elem: any) => {
        if (elem['status']) {
          this.getData();
          this.cos.openSnackBar({ message: elem.message });
        }
      })
      .catch(err => {
        this.cos.openSnackBar({ message: err.message });
      });

  }


  submitLegalForm() {
    const data = {
      legal: [
        {
          title: 'TERMS_AND_CONDITIONS',
          value: this.termsForm.value.value,
          description: 'Offical Condition'
        },
        {
          title: 'PRIVACY_POLICY',
          value: this.policyForm.value.value,
          description: 'Offical Privacy Policy',
          url: 'www.relinns.com'
        },
        {
          title: 'CANCEL_POLICY',
          value: this.cancellationForm.value.value,
          description: 'Offical Cancilation Policy',
          url: 'www.relinns.com'
        }
      ],
    };
    this.settingSrvc.putSetting(data)
      .then((elem: any) => {
        if (elem['status']) {
          this.getData();
          this.cos.openSnackBar({ message: elem.message });
        }
      })
      .catch(err => {
        this.cos.openSnackBar({ message: err.message });
      });
  }


  getData() {
    this.spinner.show();
    this.settingSrvc.getSetting()
      .then((res: DefaultRes) => {
        this.spinner.hide();

        const data = res.data;

        this.contactForm.patchValue({
          name: data.name,
          email: data.contact[1] ? data.contact[1].value : '',
          phone: data.contact[0].value,
          address: data.address.streetAddress
        });

        this.policyForm.patchValue({
          value: data.legal['1'].value
        });

        this.termsForm.patchValue({
          value: data.legal['0'].value
        });

        this.cancellationForm.patchValue({
          value: data.legal['2'].value
        });

      });
  }


  checkNumber(event) {
    const code = (event.which) ? event.which : event.keyCode;
    if (code > 31 && (code < 48 || code > 57)) {
      return false;
    }
    return true;
  }


  getCharge() {
    this.spinner.show();
    this.settingSrvc.getCharge()
      .then((res: any) => {    
        this.charge = res.data ? res.data : {}
        this.chargeValue = res.data.tax.totalAmount;
        this.type = res.data['type'],
          this.id = res.data['_id']
      }, error => {
        this.charge = {}
      });
  }

  getAdditionalCharge(value) {
    this.chargeValue = value
  }

  getType(value) {
    this.type = value
  }


  updateCharge() {   
    if (!this.chargeValue) {
      return this.cos.openSnackBar({ message: 'Please select valid tax value' });
    }
    let data = {}
    if (this.id) {
      data['id'] = this.id,
        data['tax'] = { totalAmount: String(this.chargeValue), units: this.charge.tax? this.charge.tax.units :'AUD'}
      // data['deliveryCharge'] = {totalAmount:String(this.deliveryCharge), units : this.charge['deliveryCharge']['units'] }
      data['type'] = this.type
    } else {
      data['tax'] = { totalAmount: String(this.chargeValue), units: this.charge.tax? this.charge.tax.units :'AUD' }
      // data['deliveryCharge'] = {totalAmount:String(this.deliveryCharge), units : this.charge['deliveryCharge']['units'] }
      data['type'] = this.type
    }
    this.settingSrvc.postCharge(data)
      .then((res: DefaultRes) => {
        this.getCharge()
        this.cos.openSnackBar({ message: 'Updated Successfully' });
      }, error => {
        this.cos.openSnackBar({ message: error.message });
      });
  }
}

export interface PeriodicElement {

  name: string;
  address: AddressSchema;
  contact: [ComponentSchema, ComponentSchema];
  legal: [ComponentSchema, ComponentSchema, ComponentSchema];
  founder: [string];
  foundingDate: Date;
  logo: [string];
  links: [ComponentSchema];
}

export interface ComponentSchema {
  title: string;
  value: string;
  description: string;
  // image: string;
  // url: string;
}

export interface AddressSchema {
  streetAddress: string;
  locality: string;
  subLocality: string;
  region: string;
  country: string;
  postalCode: string;
  geo: {
    lat: string;
    lng: string;
  };
}

export interface DefaultRes {
  data: null | PeriodicElement;
  message: string;
  status: boolean;
}
