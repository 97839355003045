import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from '../common.service';
import { BannerService } from './banner.service';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
const ELEMENT_DATA: any[] = [];
declare var $: any;

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent implements OnInit {
  bannerInfo: any[] = [];
  modalType = 'Add Banner';
  fileType = ['image'];
  deleteIDs = new Array();
  banner: any = '';
  showProgress = false;
  displayedColumns: string[] = ['select', 'image', 'action'];
  selection = new SelectionModel<any>(true, []);
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild('uploadImage', { static: false }) uploadImage;

  pageSizeOptions: number[] = [5, 10, 25, 100];

  constructor(
    private cos: CommonService,
    private spinner: NgxSpinnerService,
    private bannerSrvc: BannerService,
    private fb: FormBuilder
  ) { }

  bannerForm = this.fb.group({
    bannerId: [''],
    image: ['', [Validators.required]]
  });

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */


  ngOnInit() {
    this.getData();
  }

  getData() {
    this.bannerSrvc.getBanner()
      .then((res: any) => {
        if (!res.status) {
          this.cos.openSnackBar({ message: res.message });
        }
        // this.dataSource.data = res.data;
        this.bannerInfo = res.data;
      })
      .then(() => this.spinner.hide())
      .catch(err => {
        this.spinner.hide();
        this.cos.openSnackBar({ message: err.message });
      });
  }

  resetForm() {
    this.bannerForm.reset();
    this.banner = '';
    this.uploadImage.nativeElement.value = '';
  }

  submitBanner() {

    if (this.bannerForm.value.bannerId) {
      this.editBanner();
    } else {
      this.addBanner();
    }
  }

  addBanner() {

    delete this.bannerForm.value.bannerId;

    this.bannerSrvc.addBanner(this.bannerForm.value)
      .then((res: any) => {
        if (res.status) {
          this.cos.openSnackBar({ message: res.message });
          this.getData();
          this.resetForm();
          $('#add-banner').modal('hide');
        } else {
          this.cos.openSnackBar({ message: res.message });
        }
      });
  }

  editBanner() {
    this.bannerSrvc.putBanner(this.bannerForm.value)
      .then((res: any) => {
        if (res.status) {
          this.cos.openSnackBar({ message: res.message });
          this.getData();
          this.resetForm();
          $('#add-banner').modal('hide');
        } else {
          this.cos.openSnackBar({ message: res.message });
        }
      });
  }

  async bulkDelete() {
    const userIds = await this.selection.selected.map(selec => selec._id);
    if (userIds.length === 0) {
      this.cos.openSnackBar({ message: 'Please Select Banner to delete !' });
      return;
    }
    this.deleteIDs = userIds;
    this.deleteUser();
  }

  async singleDelete(ids) {
    await this.deleteIDs.push(ids);
    this.deleteUser();
  }

  deleteUser() {

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.bannerSrvc.deleteBanner({ ids: this.deleteIDs })
          .then((res: any) => {
            this.cos.openSnackBar({ message: res.message });
            this.bannerInfo = this.bannerInfo.filter(obj => !new Set(this.deleteIDs).has(obj._id));
          })
          .catch(err => {
            this.cos.debugMail(err);
            this.cos.openSnackBar({ message: err.message });
          });
      }
    });

  }

  setValueToModal(element) {
    this.bannerForm.patchValue({
      bannerId: element._id,
      image: element.image
    });

    this.banner = element.image;
  }

  uploadBanner() {
    this.showProgress = true;
    const fi = this.uploadImage.nativeElement;
    const imageData = new FormData();

    if (fi.files && fi.files[0] && fi.files[0].type.includes('image')) {

      imageData.append('file', fi.files[0]); // data to upload ...

      const reader = new FileReader(); // data to display ...
      reader.readAsDataURL(fi.files[0]);
      reader.onload = () => {
        this.banner = reader.result;
      };
    } else {
      this.showProgress = false;
      this.cos.openSnackBar({ message: 'Only Type Images can be uploaded' });
    }

    this.bannerSrvc.uploadImage(imageData)
      .then((res: any) => {
        if (!res.status) { return; }
        this.bannerForm.patchValue({
          image: res.data
        });
        this.showProgress = false;
      })
      .catch((err) => this.cos.debugMail(err));
  }

}
