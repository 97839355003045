import { Component, OnInit,ViewChild } from '@angular/core'; 
import {MatPaginator,MatSort, MatTableDataSource} from '@angular/material';
import { HttpErrorResponse,HttpClient } from '@angular/common/http'; 
// import {CustomerService} from '../services/customer.service';
import {SelectionModel} from '@angular/cdk/collections';
declare var $ :any;

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {
  constructor(private httpService: HttpClient) { }
  //arrBirds: string [];    //get data
  customerdata:any; 
  hide = true;
  displayedColumns: string[] = ['select','question', 'answer','status','action'];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  selection = new SelectionModel<PeriodicElement>(true, []);
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  @ViewChild(MatSort,{static:false}) sort: MatSort;
  @ViewChild(MatPaginator,{static:false}) paginator: MatPaginator;
  fltrtoggle(){
   
    $('#fltrdown').toggle();
 
}

  ngOnInit() { 
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    // this. getdata();
  }  


   onSelect(item: any) {
// You get the Id of the selected item here
}
}
export interface PeriodicElement {
  question: string;
  answer:string; 
   status:boolean; 
  action:string;
}
const ELEMENT_DATA: PeriodicElement[] = [
  {  question:'Can I give you my items with my hangers or bag?', answer:'Unfortunately, it would be too hard for us to track which hangers belong to which customers, so please give us your items without any personal hangers or bags.',status:true,action:''}

];



