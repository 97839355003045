import { Injectable } from '@angular/core';
import { CommonService } from '../common.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BannerService {

  BANNER = 'inventory/banner';

  constructor(private cos: CommonService) { }

  getBanner() {
    return new Promise((resolve, reject) => {
      this.cos.httpRequest(this.BANNER)
        .then(resolve)
        .catch(reject);
    });
  }

  addBanner(data) {
    return new Promise((resolve, reject) => {
      this.cos.httpRequest(this.BANNER, data, 'POST')
        .then(resolve)
        .catch(reject);
    });
  }

  putBanner(data) {
    return new Promise((resolve, reject) => {
      this.cos.httpRequest(this.BANNER, data, 'PUT')
        .then(resolve)
        .catch(reject);
    });
  }

  deleteBanner(data) {
    return new Promise((resolve, reject) => {
      this.cos.httpRequest(this.BANNER, data, 'DELETE')
        .then(resolve)
        .catch(reject);
    });
  }

  uploadImage(data) {
    return new Promise((resolve, reject) => {
      this.cos.httpFormRequest(environment.UPLOAD, data)
        .then(resolve)
        .catch(reject);
    });
  }
}
