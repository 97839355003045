import { Router } from '@angular/router';
// import { CommonService } from './../common.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { CommonService } from 'src/app/common.service';
import { resolve } from 'url';


declare const $;
@Injectable({
    providedIn: 'root'
})

export class FaqService {
    errorMessage = ''
    errorMessage1 = ''
    faqs: any = []
    FAQ_URL = 'settings/faq';
    constructor(private cos: CommonService, private router: Router) { }
    getFaq = () => new Promise((resolve, reject) => {
        this.cos.httpRequest(this.FAQ_URL, false, 'GET', true)
            .then((res: any) => {
                if (res.status) {
                    return resolve(res['data'])
                } else {
                    return resolve([])
                }
            })
            .catch(err => { return resolve([]) });
    })

    addFaq(data) {
        this.cos.httpRequest(this.FAQ_URL, data, 'POST', true)
            .then((res: any) => {
                if (res.status) {
                    $('#addfaq').modal('hide')
                    return this.cos.openSnackBar({ message: 'Faq added successfully' });
                } else {
                    this.errorMessage = res.message;
                }
            })
            .catch(err => { this.errorMessage = err.message });
    }

    editFaq(data) {
        this.cos.httpRequest(this.FAQ_URL, data, 'PUT', true)
            .then((res: any) => {
                if (res.status) {
                    $('#editfaq').modal('hide')
                    return this.cos.openSnackBar({ message: 'Faq update successfully' });
                } else {
                    this.errorMessage1 = res.message;
                }
            })
            .catch(err => { this.errorMessage1 = err.message });
    }

    deleteFaq(id) {
        this.cos.httpRequest(this.FAQ_URL, {id:id}, 'DELETE', true)
            .then((res: any) => {
                if (res.status) {
                    return this.cos.openSnackBar({ message: 'Faq Deleted successfully'});
                } else {
                    return this.cos.openSnackBar({ message: res.message});
                }
            })
            .catch(err => { return this.cos.openSnackBar({ message: err.message}); });
    }

}