import { ProfileService } from './../profile/profile.service';
import { CommonService } from './../common.service';
import { UserService } from './../users/user.service';
import { FormBuilder, Validators } from '@angular/forms';
import { LoginService } from 'src/app/login/login.service';
import { Component, OnInit, ViewChild, OnDestroy, Input } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-admin-profile',
  templateUrl: './admin-profile.component.html',
  styleUrls: ['./admin-profile.component.css']
})
export class AdminProfileComponent implements OnInit, OnDestroy {

  adminDetail = {
    contact: { email: '', phone: '' },
    userName: 'User',
    picture: 'assets/images/default.jpg',
    _id: '',
    role: ''
  };

  adminObservable: any;
  profilePic: any;

  @ViewChild('uploadImage', { static: false }) uploadImage;
  @Input() diameter = 150;
  @Input() strokeWidth = 5;

  showSpinner = false;

  constructor(
    private loginSrvc: LoginService,
    private usrSrvc: UserService,
    private cos: CommonService,
    private fb: FormBuilder,
    private profileSrvc: ProfileService) {
    this.adminObservable = this.loginSrvc.getAdminDetail.subscribe((res) => {
      this.adminDetail = { ...this.adminDetail, ...res };
      this.adminProfileForm.patchValue({
        id: this.adminDetail._id,
        name: this.adminDetail.userName,
        email: this.adminDetail.contact.email,
        phone: this.adminDetail.contact.phone,
        picture: this.adminDetail.picture,
      });
    });
  }

  adminProfileForm = this.fb.group({
    id: [''],
    name: ['', [Validators.required]],
    email: ['', [Validators.required]],
    phone: ['', [Validators.required, Validators.minLength(7)]],
    picture: ['', [Validators.required]]
  });

  ngOnInit() { }

  editAdmin() {
    if (this.adminProfileForm.invalid) { return; }

    const data = {
      picture: this.adminProfileForm.value.picture,
      name: {
        userName: this.adminProfileForm.value.name
      },
      contact: {
        email: this.adminProfileForm.value.email,
        phone: this.adminProfileForm.value.phone
      },
      userId: this.adminProfileForm.value.id,
    };

    this.usrSrvc.putUser(data)
      .then((res: DefaultRes) => {
        if (res.status) {
          this.cos.openSnackBar({ message: res.message });

          this.adminDetail = {
            role: res.data.role,
            contact: { email: res.data.contact.email, phone: res.data.contact.phone },
            userName: res.data.name.userName,
            picture: res.data.picture,
            _id: res.data._id,
          };

          this.loginSrvc.updateAdminDetail(this.adminDetail);
          localStorage.setItem('observable', btoa(JSON.stringify(this.adminDetail)));
          $('#editprofile').modal('hide');
        } else {
          this.cos.openSnackBar({ message: res.message });
        }
      })
      .catch(err => {
        this.cos.openSnackBar({ message: err.message });
      });
  }

  resetForm() {
    this.adminProfileForm.reset();
  }

  uploadProfileImg() {
    this.showSpinner = true;

    const fi = this.uploadImage.nativeElement;
    const imageData = new FormData();

    if (fi.files && fi.files[0] && fi.files[0].type.includes('image')) {

      imageData.append('file', fi.files[0]); // data to upload ...

      const reader = new FileReader(); // data to display ...
      reader.readAsDataURL(fi.files[0]);
      reader.onload = () => {
        this.profilePic = reader.result;
      };
    } else {
      this.showSpinner = false;
      this.cos.openSnackBar({ message: 'Only Type Images can be uploaded' });
    }

    this.profileSrvc.uploadImage(imageData)
      .then((res: Upload) => {
        if (!res.status) { return; }
        this.adminProfileForm.patchValue({
          picture: res.data
        });
      })
      .then(() => this.showSpinner = false)
      .catch();
  }

  ngOnDestroy(): void {
    this.adminObservable.unsubscribe();
  }

}

export interface Upload {
  data: string;
  message: string;
  status: boolean;
}

export interface DefaultRes {
  data: PeriodicElement | null;
  message: string;
  status: boolean;
}

export interface PeriodicElement {
  contact: { email: string, phone: string };
  name: {
    userName: string;
  };
  picture: string;
  _id: string;
  role: string;
}
