import { CommonService } from './../../common.service';
import { DefaultRes } from './../../promocode/promocode.component';
import { ChangePasswordService } from './change-password.service';
import { FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { LoginService } from 'src/app/login/login.service';

declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {

  adminDetail = {
    contact: { email: '', phone: '' },
    userName: 'User',
    picture: 'assets/images/default.jpg',
    _id: ''
  };
  adminObservable: any;

  userDetail = JSON.parse(localStorage.getItem('userDetail'));

  constructor(
    private loginSrvc: LoginService,
    private fb: FormBuilder,
    private chngPassSrvc: ChangePasswordService,
    private cos: CommonService) {

    this.adminObservable = this.loginSrvc.getAdminDetail.subscribe((res) => {
      this.adminDetail = { ...this.adminDetail, ...res };
    });
  }

  passwordForm = this.fb.group({
    oldPassword: ['', [Validators.required]],
    newPassword: ['', [Validators.required]],
    confirmPassword: ['', [Validators.required]]
  });

  ngOnInit() { }
  addclass() {
    document.body.classList.toggle('mini-navbar');
  }

  logout() {
    localStorage.clear();
  }

  ngOnDestroy(): void {
    this.adminObservable.unsubscribe();
  }

  resetPassword() {
    this.passwordForm.reset();
  }

  changePassword() {

    if (this.passwordForm.value.confirmPassword !== this.passwordForm.value.newPassword) {
      this.cos.openSnackBar({ message: 'New password and Confirm Password dosenot match !' });
      return;
    }

    delete this.passwordForm.value.confirmPassword;

    this.chngPassSrvc.changePassword(this.passwordForm.value)
      .then((res: DefaultRes) => {
        if (res.status) {
          this.cos.openSnackBar({ message: res.message });
          $('#changepassword').modal('hide');

          this.passwordForm.reset();
        } else {
          this.cos.debugMail(res);
          this.cos.openSnackBar({ message: res.message });
        }
      })
      .catch(err => {
        this.cos.debugMail(err);
        this.cos.openSnackBar({ message: err.message });
      });
  }

}
