import { Component, OnInit,ViewChild } from '@angular/core'; 
import {MatPaginator,MatSort, MatTableDataSource} from '@angular/material';
import { HttpErrorResponse,HttpClient } from '@angular/common/http'; 
// import {CustomerService} from '../services/customer.service';
import {SelectionModel} from '@angular/cdk/collections';
declare var $ :any;

@Component({
  selector: 'app-enquiries',
  templateUrl: './enquiries.component.html',
  styleUrls: ['./enquiries.component.css']
})
export class EnquiriesComponent implements OnInit {

  constructor(private httpService: HttpClient) { }
  //arrBirds: string [];    //get data
  customerdata:any; 
  hide = true;
  displayedColumns: string[] = ['select','name','contactNo','email','description','enquiriesDate','action'];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  selection = new SelectionModel<PeriodicElement>(true, []);
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  @ViewChild(MatSort,{static:false}) sort: MatSort;
  @ViewChild(MatPaginator,{static:false}) paginator: MatPaginator;
  fltrtoggle(){
   
    $('#fltrdown').toggle();
 
}

  ngOnInit() { 
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    // this. getdata();
  }  

   onSelect(item: any) {
    // alert();
}
}
export interface PeriodicElement {
  name: string; 
  contactNo: string; 
  email: string;    
  description:string;
  enquiriesDate: string;   
  action:string;
}
const ELEMENT_DATA: PeriodicElement[] = [
  {  name:'farooq amin',contactNo:'5122937846',email:'farooqa@hotmail.com',description:'I have many years of laundry experience and currently own a coin laundry. I want to expand my wash and fold service using your platform. I am based in Austin, Texas. Please let me know if you can assist me. Thank you.',enquiriesDate:'6 March, 2019',action:''}

];