import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonService } from '../common.service';

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  GET_SHOP_ADDRES = 'inventory/charge​/getShopAddress';
  GET_SHOP_ADDRESS = 'inventory/charge/getShopAddress';
  POST_SHOP_ADDRESS = 'inventory/charge/addShopAddress';

  constructor(private cos: CommonService) { }

  getAddress() {
    return new Promise((resolve, reject) => {
      this.cos.httpRequest(this.GET_SHOP_ADDRESS)
        .then(resolve)
        .catch(reject);
    });
  }

  postAddress(data) {
    return new Promise((resolve, reject) => {
      this.cos.httpRequest(this.POST_SHOP_ADDRESS, data, 'POST')
        .then(resolve)
        .catch(reject);
    });
  }

  public getPosition(): Observable<Position> {
    return new Observable(
      (observer) => {
        navigator.geolocation.watchPosition((pos: Position) => {
          observer.next(pos);
        });
      });
  }

}
