import { CommonService } from './../common.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  GET_USERS = 'api/user/role/';
  POST_USER = 'api/user/admin/user';
  BLOCK_UNBLOCK = 'api/user/block';
  USER = 'api/user/';
  IN_TRANSIT = 'api/user/in-transit-agent';
  GET_LAST_LOCATION = 'api/user/getLocation?agentId=';

  constructor(private cos: CommonService) { }

  getUser(obj) {
    return new Promise((resolve, reject) => {
      this.cos.httpRequest(this.GET_USERS + obj.type + `?search=` + obj.search + `&page=${obj.pageNumber}&limit=${obj.noOfRecord}`)
        .then(resolve)
        .catch(reject);
    });
  }

  getDashUser(obj) {
    return new Promise((resolve, reject) => {
      this.cos.httpRequest(this.GET_USERS + obj.type + `?search=` + obj.search + `&page=${obj.pageNumber}&limit=${obj.noOfRecord}&status=${obj.status}`)
        .then(resolve)
        .catch(reject);
    });
  }

  getIntransit(data) {
    return new Promise((resolve, reject) => {
      this.cos.httpRequest(this.IN_TRANSIT, { search: data.search }, 'POST')
        .then(resolve)
        .catch(reject);
    });
  }

  getLastLocation(id) {

    return new Promise((resolve, reject) => {
      this.cos.httpRequest(this.GET_LAST_LOCATION + id)
        .then(resolve)
        .catch(reject);
    });

  }

  postUser(data) {
    return new Promise((resolve, reject) => {
      this.cos.httpRequest(this.POST_USER, data, 'POST')
        .then(resolve)
        .catch(reject);
    });
  }

  putUser(data) {
    return new Promise((resolve, reject) => {
      this.cos.httpRequest(this.USER, data, 'PUT')
        .then(resolve)
        .catch(reject);
    });
  }

  blockUnblock(data) {
    return new Promise((resolve, reject) => {
      this.cos.httpRequest(this.BLOCK_UNBLOCK, data, 'POST')
        .then(resolve)
        .catch(reject);
    });
  }

  delete(data) {
    return new Promise((resolve, reject) => {
      this.cos.httpRequest(this.USER, data, 'DELETE')
        .then(resolve)
        .catch(reject);
    });
  }
}
