import { CommonService } from './../common.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  ORDER = 'inventory/order';
  SINGLE_USER_ORDER = 'inventory/order/singleUserOrder';
  SINGLE_USER_ITEM = 'inventory/order/singleOrderItems?id=';

  constructor(private cos: CommonService) { }

  getOrders(obj) {
    return new Promise((resolve, reject) => {

      this.cos.httpRequest(`${this.ORDER}?search=${obj.search}&page=${obj.pageNumber}&limit=${obj.noOfRecord}&status=${obj.status}&orderType=${obj.orderType}`)
        .then(resolve)
        .catch(reject);
    });
  }

  putOrder(data) {
    return new Promise((resolve, reject) => {
      this.cos.httpRequest(this.ORDER, data, 'PUT')
        .then(resolve)
        .catch(reject);
    });
  }

  delete(data) {
    return new Promise((resolve, reject) => {
      this.cos.httpRequest(this.ORDER, data, 'DELETE')
        .then(resolve)
        .catch(reject);
    });
  }

  getUserOrder(obj) {
    return new Promise((resolve, reject) => {
      this.cos.httpRequest(`${this.ORDER}?search=` + obj.search + `&page=${obj.pageNumber}&limit=${obj.noOfRecord}&status=${obj.status}`)
        .then(resolve)
        .catch(reject);
    });
  }

  getSingleUserOrder(id, type, uType) {
    return new Promise((resolve, reject) => {
      this.cos.httpRequest(this.SINGLE_USER_ORDER + '?id=' + id +'&type='+type+'&uType='+uType)
        .then(resolve)
        .catch(reject);
    });
  }

  getSingleUserItem(id) {
    return new Promise((resolve, reject) => {
      this.cos.httpRequest(this.SINGLE_USER_ITEM + id)
        .then(resolve)
        .catch(reject);
    });
  }

  getOrderRightModal(id) {
    return new Promise((resolve, reject) => {

      this.cos.httpRequest(`${this.ORDER}/${id}`)
        .then(resolve)
        .catch(reject);
    });
  }

  assignOrder(data) {
    return new Promise((resolve, reject) => {
      this.cos.httpRequest(`${this.ORDER}/assignAgent`, data, 'POST')
        .then(resolve)
        .catch(reject);
    });
  }

  getPdfData() {
    return new Promise((resolve, reject) => {
      this.cos.httpRequest(`${this.ORDER}/getPdfData`, {
        "orderId": "5e9407250a394f19934da98a",
        "userId": "5e93ef26594f2b18de67388b"
      }, 'POST')
        .then(resolve)
        .catch(reject);
    });
  }

}
