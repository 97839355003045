import { CommonService } from './../common.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ReviewService {

  REVIEW = 'rating/rating/';
  USER_DATA = 'api/user?userId=';

  constructor(private cos: CommonService) { }

  getReview(obj) {
    return new Promise((resolve, reject) => {
      this.cos.httpRequest(this.REVIEW + `admin/admin?search=` + obj.search + `&page=${obj.pageNumber}&limit=${obj.noOfRecord}`)
        .then(resolve)
        .catch(reject);
    });
  }

  delete(data) {
    return new Promise((resolve, reject) => {
      this.cos.httpRequest(`${this.REVIEW}`, data, 'DELETE')
        .then(resolve)
        .catch(reject);
    });
  }

  getData(id) {
    return new Promise((resolve, reject) => {
      this.cos.httpRequest(this.USER_DATA + id)
        .then(resolve)
        .catch(reject);
    });
  }

}
