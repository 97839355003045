import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from './../common.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, PageEvent } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { OrdersService } from './orders.service';
import Swal from 'sweetalert2';
import html2canvas from 'html2canvas';
import * as jsPDF from 'jspdf';

declare var $: any;
const ELEMENT_DATA: PeriodicElement[] = [];

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit {

  constructor(
    private orderSrvc: OrdersService,
    private cos: CommonService,
    private spinner: NgxSpinnerService,
  ) { }

  deleteIDs = new Array();

  customerdata: any;
  itemDetails: any = [];

  selectedFilter = 'All';

  searchObj = {
    search: '',
    pageNumber: 1,
    noOfRecord: 10,
    status: '',
    orderType: 'PICKUP'
  };
  orderStatus = [
    { key: 'PENDING', value: 'Pending', isDisabled: true },
    { key: 'ACCEPTED', value: 'Accepted', isDisabled: true },
    { key: 'LINEN PICKED UP', value: 'Linen Picked up', isDisabled: true },
    { key: 'LINEN REACHED TO SHOP', value: 'Linen Reached To Shop', isDisabled: true },
    { key: 'REACHED TO PICKUP LINEN', value: 'Reached To Pickup Linen', isDisabled: true },
    { key: 'WASHING', value: 'Washing', isDisabled: false },
    { key: 'WASHED', value: 'Washed', isDisabled: false },
    { key: 'READY FOR DELIVERY', value: 'Ready for Delivery', isDisabled: false },
    { key: 'ON THE WAY', value: 'On The Way', isDisabled: true },
    // { key: 'REACHED TO PICKUP', value: 'Reached To Pickup' },
    { key: 'OUT FOR DELIVERY', value: 'Out for Delivery', isDisabled: true },
    // { key: 'DELIVERD', value: 'Delivered', isDisabled: true },
    { key: 'CANCELLED', value: 'Cancelled', isDisabled: true },
    { key: 'COMPLETED', value: 'Completed', isDisabled: true },
    { key: 'REJECTED', value: 'Rejected', isDisabled: true }
  ];

  pager = {
    totalItems: 0,
    currentPage: 1,
    pageSize: 10,
    totalPages: 10,
    startPage: 1,
    endPage: 10,
    startIndex: 1,
    endIndex: 10,
    pages: []
  };

  rightModalData = {
    orderDetail: {} as any
  };
  typing: any = null;

  length = 100;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  columnType = 'PICKUP';
  driverType = false;

  displayedColumns: string[] = ['select', 'orderId', 'pickupDate', 'deliveryDate', 'item', 'price',
    'status', 'action'];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  selection = new SelectionModel<PeriodicElement>(true, []);
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  pageEvent: PageEvent;

  showColumn(type) {
    this.columnType = type;
    this.searchObj.orderType = type;
    this.getOrder();
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }
  applyFilter(filterValue: string) {
    const value = filterValue.trim().toLowerCase();
    clearInterval(this.typing);
    this.searchObj.search = value;
    this.searchObj.pageNumber = 1;
    this.typing = setTimeout(() => this.getOrder(), 500);
  }
  fltrtoggle() {
    $('#fltrdown').toggle();
  }

  ngOnInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.getOrder();
  }

  getType(event) {
    this.selectedFilter = event.target.value;
    this.searchObj.status = event.target.value;
    this.getOrder();
  }

  updateOrderStatus(obj) {
    this.orderSrvc.putOrder(obj)
      .then((res: any) => this.cos.openSnackBar({ message: res.message }))
      .catch(err => this.cos.openSnackBar({ message: err.message }));
  }

  getOrder() {
    this.spinner.show();
    this.orderSrvc.getOrders(this.searchObj)
      .then((res: PaginationRes) => {
        if (!res.status) {
          this.cos.openSnackBar({ message: res.message });

        }
        this.dataSource.data = res.data;
        this.pager = this.cos.getPager(Number(res.totalRecords), Number(res.currentPage));
        this.length = this.pager.totalItems;
        this.pageSize = this.pager.pageSize;
      })
      .then(() => this.spinner.hide())
      .catch(err => {
        this.spinner.hide();
        this.cos.openSnackBar({ message: err.message });
      });
  }

  setPage(page: PageEvent): PageEvent {
    this.searchObj.pageNumber = Number(page.pageIndex + 1);
    this.searchObj.noOfRecord = Number(page.pageSize);
    this.getOrder();
    return;
  }

  async bulkDelete() {
    const userIds = await this.selection.selected.map(selec => selec._id);
    if (userIds.length === 0) {
      this.cos.openSnackBar({ message: 'Please Select Order to delete !' });
      return;
    }
    this.deleteIDs = userIds;
    this.deleteUser();
  }

  async singleDelete(ids) {
    await this.deleteIDs.push(ids);
    this.deleteUser();
  }

  itemDetail(elem) {
    this.orderSrvc.getSingleUserItem(elem._id)
      .then((res: any) => {
        this.itemDetails = res.data;
      })
      .catch(err => {
        this.cos.openSnackBar({ message: err.message });
      });
  }

  rightModal(id) {

    this.orderSrvc.getOrderRightModal(id)
      .then((res: any) => {
        if (res.status) {
          this.rightModalData.orderDetail = res.data;

        } else {
          this.cos.openSnackBar({ message: res.message });
        }
      })
      .catch(err => {
        this.cos.openSnackBar({ message: err.message });
      });

  }

  deleteUser() {

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.orderSrvc.delete({ ids: this.deleteIDs })
          .then((res: DefaultRes) => {
            this.cos.openSnackBar({ message: res.message });
            this.dataSource.data = this.dataSource.data.filter(obj => !new Set(this.deleteIDs).has(obj._id));
          })
          .catch(err => {
            this.cos.debugMail(err);
            this.cos.openSnackBar({ message: err.message });
          });
      }
    });
  }

  // getPdf() {

  //   this.orderSrvc.getPdfData()
  //     .then((result: any) => {
  //       let pdf = new jsPDF()
  //       // debugger;
  //       pdf.fromHTML(result.data, 5, 5, {width:"100%"})
  //       pdf.save('n.pdf')
  //     })
  //     .then(err => {

  //     })
  // }


}
export interface PeriodicElement {
  createdAt: Date;
  deliveryAddress: { streetAddress: string };
  deliveryCharge: {
    totalAmount: number;
    units: string;
  };
  deliveryDate: Date;
  isDeleted: boolean;
  items: [Items];
  pickupAddress: { streetAddress: string };
  pickupDate: Date;
  pickupTime: string;
  status: string;
  updatedAt: Date;
  pickupAgentDetails: { name: { userName: string } };
  userDetails: { name: { userName: string } };
  _id: string;
  _user: string;
}

export interface Items {
  price: { units: string, totalAmount: number };
  quantity: number;
  _id: string;
  _product: string;
  _service: string;
}

export interface DefaultRes {
  data: null | PeriodicElement;
  message: string;
  status: boolean;
}

export interface PaginationRes {
  currentPage: number;
  data: [PeriodicElement];
  message: string;
  status: boolean;
  totalPage: number;
  totalRecords: number;
}
