import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from './../common.service';
import { OrdersService } from './../orders/orders.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, PageEvent } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import Swal from 'sweetalert2';
const ELEMENT_DATA: PeriodicElement[] = [];

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.css']
})
export class OrderDetailsComponent implements OnInit {


  rightModalData = {
    orderDetail: {} as any
  };

  itemDetails: any = [];

  constructor(
    private orderSrvc: OrdersService,
    private cos: CommonService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute
  ) { }

  deleteIDs = new Array();

  customerdata: any;

  selectedFilter = 'All';

  orderStatus = [
    { key: 'PENDING', value: 'Pending', isDisabled: true },
    { key: 'ACCEPTED', value: 'Accepted', isDisabled: true },
    { key: 'LINEN PICKED UP', value: 'Linen Picked up', isDisabled: true },
    { key: 'LINEN REACHED TO SHOP', value: 'Linen Reached To Shop', isDisabled: true },
    { key: 'REACHED TO PICKUP LINEN', value: 'Reached To Pickup Linen', isDisabled: true },
    { key: 'WASHING', value: 'Washing', isDisabled: false },
    { key: 'WASHED', value: 'Washed', isDisabled: false },
    { key: 'READY FOR DELIVERY', value: 'Ready for Delivery', isDisabled: false },
    { key: 'ON THE WAY', value: 'On The Way', isDisabled: true },
    // { key: 'REACHED TO PICKUP', value: 'Reached To Pickup' },
    { key: 'OUT FOR DELIVERY', value: 'Out for Delivery', isDisabled: true },
    { key: 'DELIVERD', value: 'Delivered', isDisabled: true },
    { key: 'CANCELLED', value: 'Cancelled', isDisabled: true },
    { key: 'REJECTED', value: 'Rejected', isDisabled: true }
  ];

  displayedColumns: string[] = ['select', 'orderId', 'pickupDate', 'deliveryDate', 'item', 'price',
    'status', 'action'];

  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  selection = new SelectionModel<PeriodicElement>(true, []);
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  typing: any = null;

  length = 100;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25, 100];

  pageEvent: PageEvent;
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
  }

  ngOnInit() {
    this.getOrderDetails();
  }

  updateOrderStatus(obj) {
    this.orderSrvc.putOrder(obj)
      .then((res: any) => this.cos.openSnackBar({ message: res.message }))
      .catch(err => this.cos.openSnackBar({ message: err.message }));
  }

  getOrderDetails() {
    this.orderSrvc.getSingleUserOrder(this.route.snapshot.paramMap.get('id'), this.route.snapshot.paramMap.get('type'),this.route.snapshot.paramMap.get('uType'))
      .then((res: PaginationRes) => {
        if (!res.data.length) {
          this.cos.openSnackBar({ message: res.message });
        }
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource.data = res.data;
      })
      .then(() => this.spinner.hide())
      .catch(err => {
        this.cos.openSnackBar({ message: err.message });
        this.cos.debugMail(err);
      });
  }


  async bulkDelete() {
    const userIds = await this.selection.selected.map((selec: any) => selec._id);
    if (userIds.length === 0) {
      this.cos.openSnackBar({ message: 'Please Select Order to delete !' });
      return;
    }
    this.deleteIDs = userIds;
    this.deleteUser();
  }

  async singleDelete(ids) {
    await this.deleteIDs.push(ids);
    this.deleteUser();
  }

  rightModal(id) {

    this.orderSrvc.getOrderRightModal(id)
      .then((res: any) => {
        if (res.status) {
          this.rightModalData.orderDetail = res.data;
        } else {
          this.cos.openSnackBar({ message: res.message });
        }
      })
      .catch(err => {
        this.cos.openSnackBar({ message: err.message });
      });

  }

  itemDetail(elem) {
    this.orderSrvc.getSingleUserItem(elem._id)
      .then((res: any) => {
        this.itemDetails = res.data;
      })
      .catch(err => {
        this.cos.openSnackBar({ message: err.message });
      });
  }

  deleteUser() {

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.orderSrvc.delete({ ids: this.deleteIDs })
          .then((res: DefaultRes) => {
            this.cos.openSnackBar({ message: res.message });
            this.dataSource.data = this.dataSource.data.filter(obj => !new Set(this.deleteIDs).has(obj._id));
          })
          .catch(err => {
            this.cos.debugMail(err);
            this.cos.openSnackBar({ message: err.message });
          });
      }
    });
  }


}

export interface PeriodicElement {
  createdAt: Date;
  deliveryAddress: { streetAddress: string };
  deliveryCharge: {
    totalAmount: number;
    units: string;
  };
  deliveryDate: Date;
  isDeleted: boolean;
  items: [Items];
  pickupAddress: { streetAddress: string };
  pickupDate: Date;
  pickupTime: string;
  status: string;
  updatedAt: Date;
  pickupAgentDetails: { name: { userName: string } };
  userDetails: { name: { userName: string } };
  _id: string;
  _user: string;
}

export interface Items {
  price: { units: string, totalAmount: number };
  quantity: number;
  _id: string;
  _product: string;
  _service: string;
}

export interface DefaultRes {
  data: null | PeriodicElement;
  message: string;
  status: boolean;
}

export interface PaginationRes {
  currentPage: number;
  data: [PeriodicElement];
  message: string;
  status: boolean;
  totalPage: number;
  totalRecords: number;
}
