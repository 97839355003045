import { ReviewService } from './review.service';
import { PeriodicElement, PaginationRes, DefaultRes } from './../users/users.component';
import { CommonService } from './../common.service';
import { Component, OnInit, ViewChild, OnChanges, OnDestroy } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, PageEvent } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
declare var $: any;
const ELEMENT_DATA: PeriodicElement[] = [];

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.css']
})
export class ReviewComponent implements OnInit {

  customerdata: any;
  deleteIDs = new Array();
  displayedColumns: string[] = ['select', 'booking_id', 'feedback', 'customer_rating', 'action'];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  selection = new SelectionModel<PeriodicElement>(true, []);
  rightModalData: any = {
    detail: {},
    agentDetail: {},
    customerDetail: {}
  };
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  @ViewChild('uploadImage', { static: false }) uploadImage;

  constructor(
    private reviewSrvc: ReviewService,
    private cos: CommonService,
    private spinner: NgxSpinnerService,
  ) { }

  pager = {
    totalItems: 0,
    currentPage: 1,
    totalPages: 10,
    startPage: 1,
    pageSize: 10,
    endPage: 10,
    startIndex: 1,
    endIndex: 10,
    pages: []
  };

  searchObj = {
    search: '',
    pageNumber: 1,
    noOfRecord: 10
  };
  validForm: boolean;

  profilePic: any;

  length = 100;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  typing: any = null;

  pageEvent: PageEvent;

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }
  applyFilter(filterValue: string) {
    const value = filterValue.trim().toLowerCase();
    clearInterval(this.typing);
    this.searchObj.search = value;
    this.searchObj.pageNumber = 1;
    this.typing = setTimeout(() => this.getReview(), 500);
  }

  ngOnInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.getReview();
  }

  getReview() {
    this.reviewSrvc.getReview(this.searchObj)
      .then((res: PaginationRes) => {
        if (res.status) {
          this.dataSource.data = res.data;
          this.pager = this.cos.getPager(Number(res.totalRecords), Number(res.currentPage));
          this.length = this.pager.totalItems;
          this.pageSize = this.pager.pageSize;
        } else {
          this.cos.openSnackBar({ message: res.message });
        }
      })
      .then(() => this.spinner.hide())
      .catch(err => {
        this.spinner.hide();
        this.cos.openSnackBar({ message: err.message });
      });
  }

  setPage(page: PageEvent): PageEvent {
    this.searchObj.pageNumber = Number(page.pageIndex + 1);
    this.searchObj.noOfRecord = Number(page.pageSize);
    this.getReview();
    return;
  }

  rightModal(element) {

    this.rightModalData.detail = element;
    Promise.all([
      this.reviewSrvc.getData(element._user),
      this.reviewSrvc.getData(element._to)
    ])
      .then((res: any) => {
        this.rightModalData.customerDetail = res[0].data;
        this.rightModalData.agentDetail = res[1].data;
      })
      .catch();
  }

  async bulkDelete() {
    const userIds = await this.selection.selected.map(selec => selec._id);
    if (userIds.length === 0) {
      this.cos.openSnackBar({ message: 'Please Select review to delete !' });
      return;
    }
    this.deleteIDs = userIds;
    this.deleteUser();
  }

  async singleDelete(ids) {
    await this.deleteIDs.push(ids);
    this.deleteUser();
  }

  deleteUser() {

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.reviewSrvc.delete({ ids: this.deleteIDs })
          .then((res: DefaultRes) => {
            this.cos.openSnackBar({ message: res.message });
            this.dataSource.data = this.dataSource.data.filter(obj => !new Set(this.deleteIDs).has(obj._id));
            this.cos.debugMail(res);
          })
          .catch(err => {
            this.cos.openSnackBar({ message: err.message });
          });
      }
    });

  }

}
