import { NgxSpinnerService } from 'ngx-spinner';
import { ProfileService } from './../profile/profile.service';
import { Upload } from './../profile/profile.component';
import { DefaultRes, PaginationRes } from './../users/users.component';
import { UserService } from './../users/user.service';
import { CommonService } from './../common.service';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, PageEvent } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { AgentService } from './agent.service';
import { FormBuilder, Validators } from '@angular/forms';
import swal from 'sweetalert2';

declare var $: any;
const ELEMENT_DATA: PeriodicElement[] = [];

@Component({
  selector: 'app-agents',
  templateUrl: './agents.component.html',
  styleUrls: ['./agents.component.css']
})
export class AgentsComponent implements OnInit {

  modalType = 'Add Driver';
  deleteIDs = new Array();
  showSpinner = false;
  fileType = ['image', 'csv', 'xlsx', 'xls'];
  driveryLicence: any;
  idProof: any;
  pager = {
    totalItems: 0,
    currentPage: 1,
    totalPages: 10,
    startPage: 1,
    pageSize: 10,
    endPage: 10,
    startIndex: 1,
    endIndex: 10,
    pages: []
  };
  searchObj = {
    type: 'AGENT',
    search: '',
    pageNumber: 1,
    noOfRecord: 10
  };

  rightModalData = {
    agentDetail: {} as PeriodicElement,
  };

  @Input() diameter = 150;
  @Input() strokeWidth = 5;

  profilePic: any;
  Licence: any;
  IdImage: any;

  loader = {
    showLic: false,
    showId: false
  };


  @ViewChild('driverForm', { static: false }) driverForm;


  displayedColumns: string[] = ['select', 'name', 'email', 'phone', 'profile_image', 'status', 'action'];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  selection = new SelectionModel<PeriodicElement>(true, []);
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  @ViewChild('uploadImage', { static: false }) uploadImage;
  @ViewChild('uploadLic', { static: false }) uploadLic;
  @ViewChild('uploadID', { static: false }) uploadID;

  constructor(
    private profileSrvc: ProfileService,
    private usrSrvc: UserService,
    private spinner: NgxSpinnerService,
    private agntService: AgentService,
    private cos: CommonService,
    private fb: FormBuilder) { }

  agentForm = this.fb.group({
    id: [''],
    userName: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    phone: ['', [Validators.required, Validators.minLength(7), Validators.maxLength(12)]],
    address: this.fb.group({
      streetAddress: [''],
      locality: [''],
      subLocality: [''],
      region: [''],
      country: [''],
      postalCode: [''],
      geo: [''],
      formattedAddress: [''],
      landmark: ['']
    }),
    picture: ['', [Validators.required]],
    transportColor: [''],
    transportDesc: [''],
    transportNo: [''],
    transportType: [''],
    DRIVER_LICENCE: [''],
    ID_PROOF: ['']
  });
  typing: any = null;

  length = 100;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25, 100];

  pageEvent: PageEvent;

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  applyFilter(filterValue: string) {
    const value = filterValue.trim().toLowerCase();
    clearInterval(this.typing);
    this.searchObj.search = value;
    this.searchObj.pageNumber = 1;
    this.typing = setTimeout(() => this.getData(), 500);
  }

  ngOnInit() {
    this.dataSource.sort = this.sort;
    this.getData();
  }

  resetForm() {
    this.modalType = 'Add Driver';
    this.agentForm.reset();
    this.driverForm.resetForm();
  }

  getData() {
    this.spinner.show();
    this.usrSrvc.getUser(this.searchObj)
      .then((res: PaginationRes) => {
        if (!res.status) {
          this.cos.openSnackBar({ message: res.message });
        }
        this.dataSource.data = res.data;
        this.pager = this.cos.getPager(Number(res.totalRecords), Number(res.currentPage));
        this.length = this.pager.totalItems;
        this.pageSize = this.pager.pageSize;
      })
      .then(() => this.spinner.hide())
      .catch(err => {
        this.spinner.hide();
        this.cos.openSnackBar({ message: err.message });
        this.cos.debugMail(err);
      });
  }

  setPage(page: PageEvent): PageEvent {
    this.searchObj.pageNumber = Number(page.pageIndex + 1);
    this.searchObj.noOfRecord = Number(page.pageSize);
    this.getData();
    return;
  }

  blockUnblock(data) {
    this.usrSrvc.blockUnblock(data)
      .then((res: DefaultRes) => {
        if (res.status) {

          data.isActive ? this.cos.openSnackBar({ message: 'Agent Unblocked Successfully' }) :
            this.cos.openSnackBar({ message: 'Agent Blocked Successfully' });
          const object = this.dataSource.data.findIndex(x => x._id === data.userId);
          this.dataSource.data[object].isActive = data.isActive;
        } else {
          this.cos.openSnackBar({ message: res.message });
        }
      })
      .catch(err => {
        this.cos.openSnackBar({ message: err.message });
      });
  }

  getAddress(event) {
    this.agentForm.patchValue({
      address: {
        streetAddress: event.route ? event.route : 'undefined',
        locality: event.locality ? event.locality : 'undefined',
        subLocality: event.sub_locality ? event.sub_locality : 'undefined',
        region: event.country,
        country: event.country,
        postalCode: event.postal_code ? event.postal_code : 'undefined',
        geo: [event.geo[1], event.geo[0]],
        formattedAddress: event.formattedAddress,
        landmark: event.formattedAddress
      }
    });
  }

  uploadProfileImg() {

    this.showSpinner = true;
    const fi = this.uploadImage.nativeElement;
    const imageData = new FormData();

    if (fi.files && fi.files[0] && fi.files[0].type.includes('image')) {

      imageData.append('file', fi.files[0]); // data to upload ...

      const reader = new FileReader(); // data to display ...
      reader.readAsDataURL(fi.files[0]);
      reader.onload = () => {
        this.profilePic = reader.result;
      };
    } else {
      this.showSpinner = false;
      this.cos.openSnackBar({ message: 'Only Type Images can be uploaded' });
    }

    this.profileSrvc.uploadImage(imageData)
      .then((res: Upload) => {
        if (!res.status) { return; }
        this.agentForm.patchValue({
          picture: res.data
        });
      })
      .then(() => this.showSpinner = false)
      .catch((err) => this.cos.debugMail(err));
  }

  uploadLicImg() {
    this.loader.showLic = true;

    const fi = this.uploadLic.nativeElement;
    const imageData = new FormData();

    if (fi.files && fi.files[0] && this.fileType.some(el => fi.files[0].type.includes(el))) {

      imageData.append('file', fi.files[0]); // data to upload ...

      const reader = new FileReader(); // data to display ...
      reader.readAsDataURL(fi.files[0]);
      reader.onload = () => {
        this.Licence = reader.result;
      };

      this.profileSrvc.uploadImage(imageData)
        .then((res: Upload) => {
          if (!res.status) { return; }
          this.agentForm.patchValue({
            DRIVER_LICENCE: res.data
          });
          this.loader.showLic = false;

        })
        .catch((err) => this.cos.debugMail(err));

    } else {
      this.loader.showLic = false;

      this.cos.openSnackBar({ message: 'Only Type Images, xlsx, csv, xls format can be uploaded' });
    }
  }

  uploadIdImg() {

    const fi = this.uploadID.nativeElement;
    const imageData = new FormData();
    this.loader.showId = true;

    if (fi.files && fi.files[0] && this.fileType.some(el => fi.files[0].type.includes(el))) {

      imageData.append('file', fi.files[0]); // data to upload ...

      const reader = new FileReader(); // data to display ...
      reader.readAsDataURL(fi.files[0]);
      reader.onload = () => {
        this.IdImage = reader.result;
      };

      this.profileSrvc.uploadImage(imageData)
        .then((res: Upload) => {
          if (!res.status) { return; }
          this.agentForm.patchValue({
            ID_PROOF: res.data
          });
          this.loader.showId = false;

        })
        .catch((err) => this.cos.debugMail(err));
    } else {
      this.loader.showId = false;

      this.cos.openSnackBar({ message: 'Only Type Images, xlsx, csv, xls format can be uploaded' });
      this.showSpinner = false;
    }
  }

  async bulkDelete() {
    const userIds = await this.selection.selected.map(selec => selec._id);
    if (userIds.length === 0) {
      this.cos.openSnackBar({ message: 'Please Select agent to delete !' });
      return;
    }
    this.deleteIDs = userIds;
    this.deleteUser();
  }

  async singleDelete(ids) {
    await this.deleteIDs.push(ids);
    this.deleteUser();
  }

  deleteUser() {

    swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.usrSrvc.delete({ ids: this.deleteIDs })
          .then((res: DefaultRes) => {
            this.cos.openSnackBar({ message: res.message });
            this.dataSource.data = this.dataSource.data.filter(obj => !new Set(this.deleteIDs).has(obj._id));
          })
          .catch(err => {
            this.cos.debugMail(err);
            this.cos.openSnackBar({ message: err.message });
          });
      }
    });
  }

  editAgent() {
    if (this.agentForm.invalid) { return; }

    const data = {
      picture: this.agentForm.value.picture,
      name: {
        userName: this.agentForm.value.userName
      },
      contact: {
        phone: this.agentForm.value.phone,
        email: this.agentForm.value.email
      },
      address: {
        ...this.agentForm.value.address,
        type: 'WORK'
      },
      transport: {
        transportType: this.agentForm.value.transportType || 'undefined',
        transportDesc: this.agentForm.value.transportDesc || '',
        transportColor: this.agentForm.value.transportColor || '',
        transportNo: this.agentForm.value.transportNo || '',
      },
      document: [
        {
          type: 'DRIVER_LICENCE',
          name: `${this.agentForm.value.userName} Licence`,
          description: 'string',
          image: this.agentForm.value.DRIVER_LICENCE || ''
        },
        {
          type: 'ID_PROOF',
          name: `${this.agentForm.value.userName} Id Proof`,
          description: 'string',
          image: this.agentForm.value.ID_PROOF || ''
        }
      ],
      userId: this.agentForm.value.id,
    };

    this.usrSrvc.putUser(data)
      .then((res: DefaultRes) => {
        if (res.status) {
          this.cos.openSnackBar({ message: res.message });
          this.getData();
          this.resetForm();
          $('#addrider').modal('hide');
        } else {
          this.cos.openSnackBar({ message: res.message });
        }
      })
      .catch(err => {
        this.cos.debugMail(err);
        this.cos.openSnackBar({ message: err.message });
      });
  }

  addAgent() {
    if (this.agentForm.invalid) { return; }

    const data = {
      picture: this.agentForm.value.picture,
      auth: {
        passKey: this.cos.genPassword(8)
      },
      name: {
        userName: this.agentForm.value.userName
      },
      contact: {
        phone: this.agentForm.value.phone,
        email: this.agentForm.value.email
      },
      address: {
        ...this.agentForm.value.address,
        type: 'WORK',
      },
      transport: {
        transportType: this.agentForm.value.transportType || 'undefined',
        transportDesc: this.agentForm.value.transportDesc || '',
        transportColor: this.agentForm.value.transportColor || '',
        transportNo: this.agentForm.value.transportNo || '',
      },
      document: [
        {
          type: 'DRIVER_LICENCE',
          name: `${this.agentForm.value.userName} Licence`,
          description: 'string',
          image: this.agentForm.value.DRIVER_LICENCE || 'undefined'
        },
        {
          type: 'ID_PROOF',
          name: `${this.agentForm.value.userName} Id Proof`,
          description: 'string',
          image: this.agentForm.value.ID_PROOF || 'undefined'
        }
      ]
    };

    this.agntService.postAgent(data)
      .then((res: DefaultRes) => {
        if (res.status) {
          this.cos.openSnackBar({ message: res.message });
          this.getData();
          this.resetForm();
          $('#addrider').modal('hide');
        } else {
          this.cos.openSnackBar({ message: res.message });
        }
      })
      .catch((err) => this.cos.debugMail(err));
  }

  submitAgent() {
    if (this.agentForm.value.id) {
      this.editAgent();
    } else {
      this.addAgent();
    }
  }

  setValueToModal(elem) {

    this.modalType = 'Edit Driver';

    const DRIVER_LICENCE = elem.document.findIndex(e => e.type === 'DRIVER_LICENCE');
    const ID_PROOF = elem.document.findIndex(e => e.type === 'ID_PROOF');

    this.agentForm.patchValue({
      id: elem._id,
      picture: elem.picture,
      userName: elem.name.userName,
      email: elem.contact.email,
      phone: elem.contact.phone,
      address: elem.address[0],
      addressType: elem.address[0].type,
      transportColor: elem.transport.transportColor,
      transportDesc: elem.transport.transportDesc,
      transportNo: elem.transport.transportNo,
      transportType: elem.transport.transportType,
      DRIVER_LICENCE: (DRIVER_LICENCE !== -1) ? elem.document[DRIVER_LICENCE].image : '',
      ID_PROOF: (ID_PROOF !== -1) ? elem.document[ID_PROOF].image : '',
    });
  }

  rightmodal(data: PeriodicElement) {
    this.rightModalData.agentDetail = data;
    this.driveryLicence = this.rightModalData.agentDetail['document'].findIndex(e => e.type === 'DRIVER_LICENCE');
    this.idProof = this.rightModalData.agentDetail['document'].findIndex(e => e.type === 'ID_PROOF');
  }
}

export interface PeriodicElement {
  picture: string;
  verification: boolean;
  description: string;
  isAvailable: boolean;
  isDeleted: boolean;
  isActive: boolean;
  isApproved: boolean;
  userType: string;
  _id: string;
  address: [{}];
  transport: {
    transportColor: string;
    transportDesc: string;
    transportNo: string;
    transportType: string;
  };
  contact: {
    email: string;
    phone: number;
  };
  name: {
    firstName: string;
    lastName: string;
    userName: string;
  };
  role: string;
  createdAt: string;
  updatedAt: string;
}
