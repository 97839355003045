import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LoginService } from './login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private loginSrvc: LoginService) { }

  loginForm = new FormGroup({
    username: new FormControl('contact@appsrhino.com', [Validators.required]),
    password: new FormControl('relinns@123', [Validators.required]),
  });

  ngOnInit() { }

  loginSubmit() {

    const loginData = { ...this.loginForm.value, loginType: 'ADMIN' };
    this.loginSrvc.login(loginData);
  }
}
