import { PromocodeService } from './../promocode/promocode.service';
import { UserService } from './../users/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from './../common.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, PageEvent } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
const ELEMENT_DATA: PeriodicElement[] = [];

@Component({
  selector: 'app-send-promo',
  templateUrl: './send-promo.component.html',
  styleUrls: ['./send-promo.component.css']
})
export class SendPromoComponent implements OnInit {
  typing: any = null;

  pager = {
    totalItems: 0,
    currentPage: 1,
    totalPages: 10,
    startPage: 1,
    pageSize: 10,
    endPage: 10,
    startIndex: 1,
    endIndex: 10,
    pages: []
  };

  length = 100;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  searchObj = {
    type: 'USER',
    search: '',
    pageNumber: 1,
    noOfRecord: 10
  };
  modalType = 'Add Promo';

  promoOptions = {
    flatFlag: false,
    percentageFlag: true,
    referrallFlag: true,
  };

  rightModalData = {
    promoDetail: {} as PeriodicElement,
  };

  selectedPromoData: any = {};

  promoData: any = [];

  constructor(
    private usrSrvc: UserService,
    private cos: CommonService,
    private promoSrvc: PromocodeService,
    private spinner: NgxSpinnerService,
  ) { }

  pageEvent: PageEvent;

  displayedColumns: string[] = ['select', 'name', 'email', 'customerType', 'phone',
    'profile_image'];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  selection = new SelectionModel<PeriodicElement>(true, []);
  deleteIDs = new Array();

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  applyFilter(filterValue: string) {
    const value = filterValue.trim().toLowerCase();
    clearInterval(this.typing);
    this.searchObj.search = value;
    this.searchObj.pageNumber = 1;
    this.typing = setTimeout(() => this.getUsers(), 500);
  }

  ngOnInit() {
    this.getUsers();
    this.getPromo();
  }

  setPage(page: PageEvent): PageEvent {
    this.searchObj.pageNumber = Number(page.pageIndex + 1);
    this.searchObj.noOfRecord = Number(page.pageSize);
    this.getUsers();
    return;
  }

  getUsers() {
    this.spinner.show();
    this.usrSrvc.getUser(this.searchObj)
      .then((res: PaginationRes) => {
        if (res.status) {
          this.dataSource.data = res.data;
          this.pager = this.cos.getPager(Number(res.totalRecords), Number(res.currentPage));
          this.length = this.pager.totalItems;
          this.pageSize = this.pager.pageSize;
        } else {
          this.cos.openSnackBar({ message: res.message });
        }
      })
      .then(() => this.spinner.hide())
      .catch(err => {
        this.spinner.hide();
        this.cos.openSnackBar({ message: err.message });
      });
  }

  selectedPromo(elem) {
    this.selectedPromoData = {
      promoCode: elem.name,
      description: elem.description,
      validFrom: elem.createdAt,
      validThrough: elem.validThrough
    };
  }

  sendPromoToAll() {

    if (Object.entries(this.selectedPromoData).length === 0 && this.selectedPromoData.constructor === Object) {
      this.cos.openSnackBar({ message: 'Please select Promo  Code first !' });
      return;
    }

    this.promoSrvc.sendPromoToAll(this.selectedPromoData)
      .then(res => {
        this.cos.openSnackBar({ message: res['message'] });
      })
      .catch(err => {
        this.cos.openSnackBar({ message: err.message });
      });
  }

  async sendPromoToSelected() {

    if (Object.entries(this.selectedPromoData).length === 0 && this.selectedPromoData.constructor === Object) {
      this.cos.openSnackBar({ message: 'Please select Promo  Code first !' });
      return;
    }

    if (this.selection.selected.length === 0) {
      this.cos.openSnackBar({ message: 'Please Select user to send promo !' });
      return;
    }
    const promoToSend = await this.selection.selected.map(selec => selec._id);
    this.selectedPromoData.ids = await promoToSend;
    this.sendPromoToAll();

  }

  getPromo() {
    this.promoSrvc.getPromo(this.searchObj)
      .then((res: PaginationRes) => {
        if (!res.status) {
          this.cos.openSnackBar({ message: res.message });
          return;
        }
        this.promoData = res.data;
      })
      .then(() => this.spinner.hide())
      .catch(err => {
        this.cos.openSnackBar({ message: err.message });
      });
  }

}

export interface PeriodicElement {

  maxUsesPerUser: boolean;
  description: string;
  isDeleted: boolean;
  name: boolean;
  status: boolean;
  userType: string;
  _id: string;
  type: {
    promoType: string;
    flatAmount: number;
    minAmountRequired: number;
    maxAmount: number;
    flatOffForReferral: number;
    percentage: number;
  };
  termsAndCondition: string;
  createdAt: Date;
  validThrough: Date;
}

export interface PaginationRes {
  currentPage: number;
  data: [PeriodicElement];
  message: string;
  status: boolean;
  totalPage: number;
  totalRecords: number;
}

export interface DefaultRes {
  data: null | PeriodicElement;
  message: string;
  status: boolean;
}
