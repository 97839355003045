import { LoginService } from './login/login.service';
import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {

  title = 'atlasdmin';
  adminObservable: any;

  constructor(private loginSrvc: LoginService) { }

  ngOnInit(): void {

    const data = localStorage.getItem('observable') ? localStorage.getItem('observable') : null;
    this.adminObservable = this.loginSrvc.getAdminDetail.subscribe((res) => {
      if (!res._id && data) {
        this.loginSrvc.updateAdminDetail(JSON.parse(atob(data)));
      }
    });
  }
  
  ngOnDestroy(): void {
    this.adminObservable.unsubscribe();
  }

}
