import { CommonService } from './../common.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ResetPasswordService {

  RESET_PASSWORD = 'api/user/resetPasswordLink/update';

  constructor(private cos: CommonService, public router: Router) { }

  resetPassword(data) {
    return new Promise((resolve, reject) => {
      this.cos.httpRequest(this.RESET_PASSWORD, data, 'POST')
        .then(result=>{
          this.cos.openSnackBar({ message: 'Password Updated Successfully' });
          return this.router.navigate(['/']);
        })
        .catch(err=>{
          return this.cos.openSnackBar({ message: err.message });
        });
    });
  }
}
