import { PageEvent } from '@angular/material';
import { CommonService } from './../common.service';
import { UserService } from './../users/user.service';
import { PaginationRes } from './../users/users.component';
import { LoginService } from 'src/app/login/login.service';
import { environment } from './../../environments/environment';
import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import * as io from 'socket.io-client';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, OnDestroy {

  socket = io(environment.BASE_URL);
  mapObj = {
    lat: 0,
    lng: 0
  };

  intransitObj = {
    lat: 0,
    lng: 0
  };

  agentIcon = {
    url: 'assets/images/atlas-bike.png', zIndex: 999999, scaledSize: { height: 80, width: 80 }
  };

  activeClass = {
    activeIdle: '',
    activeOfline: '',
    activeIntransit: ''
  };

  search = '';
  intransit = false;
  adminDetail = {
    contact: { email: '', phone: '' },
    name: {
      userName: 'User',
    },
    picture: 'assets/images/default.jpg',
    _id: ''
  };

  pager = {
    totalItems: 0,
    currentPage: 1,
    totalPages: 10,
    startPage: 1,
    pageSize: 10,
    endPage: 10,
    startIndex: 1,
    endIndex: 10,
    pages: []
  };

  searchObj = {
    type: 'AGENT',
    search: '',
    pageNumber: 1,
    noOfRecord: 10,
    status: true
  };

  agentData = {
    inTransit: [],
    idle: [],
    offline: []
  };

  intransitData: any = [];

  origin = { lat: 0, lng: 0 };
  destination = { lat: 0, lng: 0 };

  public renderOptions = {
    polylineOptions: { strokeColor: '#000' }
  };

  adminObservable: any;
  @ViewChild('inputSearch', { static: false }) inputSearch: ElementRef;

  constructor(private loginSrvc: LoginService, private usrSrvc: UserService, private cos: CommonService
  ) {
    this.adminObservable = this.loginSrvc.getAdminDetail.subscribe((res) => {
      this.adminDetail = { ...this.adminDetail, ...res };
    });

    this.socket.on('saveSocket');
    this.socket.on('getLastLocation', (msg) => {
      const data = msg.data.location;
      if (data) {
        this.intransitObj = { ...this.intransitObj, ...{ lat: data.lat, lng: data.lng } };
      } else {
        this.getLastLocationAgent(msg.data.agentId);
      }
    });
  }

  length = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25, 100];

  pageEvent: PageEvent;

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
  }

  ngOnInit() {
    this.socket.emit('saveSocket', { id: this.adminDetail._id });
    this.getAgentData();
  }

  /**
   * Get agent details and devide it into idle and offline.
   */

  getAgentData() {
    this.usrSrvc.getDashUser(this.searchObj)
      .then((res: PaginationRes) => {
        if (!res.status) {
          this.cos.openSnackBar({ message: res.message });
        }
        this.agentData.idle = res.data.filter((type: any) => {
          if (type.lastLocation[1] !== 0 && type.lastLocation[0] !== 0) {
            if (this.mapObj.lat === 0) {
              this.mapObj.lat = type.lastLocation[1];
              this.mapObj.lng = type.lastLocation[0];
              this.activeClass.activeIdle = type._id;
            }
          }
          return type.isAvailable;
        });


        this.agentData.offline = res.data.filter((type: any) => {
          if (type.lastLocation[1] !== 0 && type.lastLocation[0] !== 0) {
            if (this.mapObj.lat === 0) {
              this.mapObj.lat = type.lastLocation[1];
              this.mapObj.lng = type.lastLocation[0];
              this.activeClass.activeOfline = type._id;
            }
          }
          return !type.isAvailable;
        });

        this.pager = this.cos.getPager(Number(res.totalRecords), Number(res.currentPage));
        this.length = this.pager.totalItems;
        this.pageSize = this.pager.pageSize;

      })
      .catch(err => {
        this.cos.openSnackBar({ message: err.message });
        this.cos.debugMail(err);
      });
  }

  getLastLocationAgent(id) {
    this.usrSrvc.getLastLocation(id)
      .then((res: any) => {
        this.intransitObj = { ...this.intransitObj, ...{ lat: res.data[1], lng: res.data[0] } };
      })
      .catch();
  }

  dashSearch(event) {

    if (event === '') {
      this.searchAgent();
    }
  }


  toogleAgent(val) {
    this.search = '';
    this.searchObj.search = '';
    if (String(val).trim() === 'intransit') {
      this.getIntransit();
      this.intransit = true;
    } else {
      this.searchObj.status = val;
      this.getAgentData();
      this.intransit = false;
    }
  }

  showAgentLoc(elem) {

    this.activeClass.activeIdle = elem._id;

    this.mapObj.lat = elem.lastLocation[1];
    this.mapObj.lng = elem.lastLocation[0];
  }

  getIntransit() {
    this.usrSrvc.getIntransit(this.searchObj)
      .then((res: any) => {
        this.agentData.inTransit = res.data;
        this.intransitData = [];
        this.pager = this.cos.getPager(Number(res.data.length));
        this.length = this.pager.totalItems;
        this.pageSize = this.pager.pageSize;
        for (let i = 0; i < (this.searchObj.pageNumber * this.searchObj.noOfRecord) && i < this.agentData.inTransit.length; i++) {
          this.intransitData.push(this.agentData.inTransit[i]);
        }

        if (this.agentData.inTransit.length > 0) {
          if (this.agentData.inTransit[0].orderType === 'PICKUP') {

            this.origin = {
              lat: this.agentData.inTransit[0].pickupAddress.geo[1],
              lng: this.agentData.inTransit[0].pickupAddress.geo[0]
            };
            this.destination = {
              lat: this.agentData.inTransit[this.agentData.inTransit.length - 1].shopAddress.geo[1],
              lng: this.agentData.inTransit[this.agentData.inTransit.length - 1].shopAddress.geo[0]
            };
            this.socket.emit('getLastLocation', { agentId: this.agentData.inTransit[0]._pickupAgent });
          } else {
            this.origin = {
              lat: this.agentData.inTransit[0].shopAddress.geo[1],
              lng: this.agentData.inTransit[0].shopAddress.geo[0]
            };
            this.destination = {
              lat: this.agentData.inTransit[this.agentData.inTransit.length - 1].deliveryAddress.geo[1],
              lng: this.agentData.inTransit[this.agentData.inTransit.length - 1].deliveryAddress.geo[0]
            };
            this.socket.emit('getLastLocation', { agentId: this.agentData.inTransit[0]._deliveryAgent });
          }
          this.activeClass.activeIntransit = this.agentData.inTransit[0]._id;
        }
      })
      .catch();
  }

  searchAgent() {
    this.searchObj.search = this.search;

    if (this.intransit) {
      this.getIntransit();
    } else {
      this.searchObj.pageNumber = 1;
      this.getAgentData();
    }

  }

  setPage(page: PageEvent): PageEvent {
    this.searchObj.pageNumber = Number(page.pageIndex + 1);
    this.searchObj.noOfRecord = Number(page.pageSize);
    this.getAgentData();
    return;
  }


  setIntransitPage(page: PageEvent): PageEvent {
    this.searchObj.pageNumber = Number(page.pageIndex + 1);
    this.searchObj.noOfRecord = Number(page.pageSize);
    this.intransitData = [];
    for (let i = (this.searchObj.pageNumber - 1) * this.searchObj.noOfRecord; i < (this.searchObj.pageNumber * this.searchObj.noOfRecord) && i < this.agentData.inTransit.length; i++) {
      this.intransitData.push(this.agentData.inTransit[i]);
    }
    return;
  }

  getAgentLoc(elem) {
    if (elem.orderType === 'PICKUP') {
      this.origin = {
        lat: elem.pickupAddress.geo[1],
        lng: elem.pickupAddress.geo[0]
      };
      this.destination = {
        lat: elem.shopAddress.geo[1],
        lng: elem.shopAddress.geo[0]
      };
      this.socket.emit('getLastLocation', { agentId: elem._pickupAgent });
    } else {
      this.origin = {
        lat: elem.shopAddress.geo[1],
        lng: elem.shopAddress.geo[0]
      };
      this.destination = {
        lat: elem.deliveryAddress.geo[1],
        lng: elem.deliveryAddress.geo[0]
      };
      this.socket.emit('getLastLocation', { agentId: elem._deliveryAgent });
    }

    this.activeClass.activeIntransit = elem._id;

  }

  ngOnDestroy(): void {
    this.adminObservable.unsubscribe();
  }

}
