import { CommonService } from './../common.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AgentService {

  POST_AGENT = 'api/user/register/agent';

  constructor(private cos: CommonService) { }

  postAgent(data) {
    return new Promise((resolve, reject) => {
      this.cos.httpRequest(this.POST_AGENT, data, 'POST')
        .then(resolve)
        .catch(reject);
    });
  }

}
