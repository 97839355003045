import { FormBuilder, Validators } from '@angular/forms';
import { ServiceAreaService } from './service-area.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from './../common.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator, MatSort, MatTableDataSource, PageEvent } from '@angular/material';
import swal from 'sweetalert2';

declare const $: any;
const ELEMENT_DATA: any[] = [];

@Component({
  selector: 'app-service-area',
  templateUrl: './service-area.component.html',
  styleUrls: ['./service-area.component.css']
})
export class ServiceAreaComponent implements OnInit {
  dataLength: any = 0
  searchObj = {
    type: 'ONE',
    search: '',
    pageNumber: 1,
    noOfRecord: 10
  };
  agmObj = {
    lat: 0,
    lng: 0
  };
  typing: any = null;
  // pager = {
  //   totalItems: 0,
  //   currentPage: 1,
  //   totalPages: 10,
  //   startPage: 1,
  //   pageSize: 10,
  //   endPage: 10,
  //   startIndex: 1,
  //   endIndex: 10,
  //   pages: []
  // };

  // length = 100;
  // pageSize = 10;
  // pageSizeOptions: number[] = [5, 10, 25, 100];
  // pageEvent: PageEvent;

  // setPageSizeOptions(setPageSizeOptionsInput: string) {
  //   this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
  // }
  deleteIDs = [];
  constructor(
    private cos: CommonService,
    private spinner: NgxSpinnerService,
    private srvcArea: ServiceAreaService,
    private fb: FormBuilder
  ) { }
  lat = -23.8779431;
  lng = -49.8046873;
  zoom = 15;
  displayedColumns: string[] = ['postalCode', 'serviceArea', 'deliveryCharge', 'action'];

  serviceForm = this.fb.group({
    serviceAreaId: ['', [Validators.required]],
    shortAddress: ['', [Validators.required]],
    address: this.fb.group({
      streetAddress: [''],
      locality: [''],
      subLocality: [''],
      region: [''],
      country: [''],
      postalCode: [''],
      geo: [''],
      formattedAddress: [''],
      landmark: ['']
    }),
    deliveryCharge: ['', Validators.required]
  });

  dataSource = new MatTableDataSource(ELEMENT_DATA);
  selection = new SelectionModel<any>(true, []);
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  // setPage(page: PageEvent): PageEvent {
  //   this.searchObj.pageNumber = Number(page.pageIndex + 1);
  //   this.searchObj.noOfRecord = Number(page.pageSize);
  //   this.getServiceArea();
  //   return;
  // }


  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.serviceArea + 1}`;
  }

  applyFilter(filterValue: string) {
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }

    this.dataSource.filterPredicate = (data, element) => (data.address.formattedAddress.toLowerCase().includes(element.trim().toLowerCase()));

    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  ngOnInit() {
    this.getServiceArea();
    this.getCurrentLocation();
    this.getCurrentLocation();
  }

  getCurrentLocation() {
    navigator.geolocation.getCurrentPosition(position => {

      this.agmObj = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      };
    });
  }

  resetForm() {
    this.serviceForm.reset();
    this.getCurrentLocation();
  }

  getServiceArea() {
    this.spinner.show();
    this.srvcArea.getServiceArea(this.searchObj)
      .then((res: any) => {
        if (!res.status) {
          this.cos.openSnackBar({ message: res.message });
        }
        this.dataSource.data = res.data;
        this.dataLength = res.data.length;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      })
      .then(() => this.spinner.hide())
      .catch(err => {
        this.spinner.hide();
        this.cos.openSnackBar({ message: err.message });
        this.cos.debugMail(err);
      });
  }

  submitServiceArea() {
    if (this.serviceForm.value.serviceAreaId) {
      this.editService();
    } else {
      this.addService();
    }
  }

  deliveryCharge(value) {

  }

  addService() {
    if (!this.serviceForm.value.deliveryCharge) {
      return this.cos.openSnackBar({ message: 'Please fill valid delivery charge' });
    }
    if (!this.serviceForm.value.address.postalCode || this.serviceForm.value.address.postalCode == 'undefined')
      return this.cos.openSnackBar({ message: 'Please select a valid address or postal code' });
    this.srvcArea.postServiceArea({
      address: this.serviceForm.value.address, deliveryCharge: {
        totalAmount: this.serviceForm.value.deliveryCharge, units: 'AUD'
      }
    })
      .then((res: any) => {
        if (res.status) {
          this.getServiceArea();
          this.resetForm();
          $('#addservicearea').modal('hide');
          this.cos.openSnackBar({ message: res.message });
        } else {
          this.cos.openSnackBar({ message: res.massage });
        }
      })
      .catch(err => {
        this.cos.debugMail(err);
        this.cos.openSnackBar({ message: err.message });
      });
  }

  setValueToModal(elem) {
    this.serviceForm.patchValue({
      serviceAreaId: elem._id,
      shortAddress: elem.address.formattedAddress,
      address: elem.address,
      deliveryCharge: String(elem.deliveryCharge.totalAmount)
    });

    this.agmObj = {
      lat: elem.address.geo[1],
      lng: elem.address.geo[0]
    };
  }

  editService() {
    if (!this.serviceForm.value.deliveryCharge) {
      return this.cos.openSnackBar({ message: 'Please fill valid delivery charge' });
    }
    if (!this.serviceForm.value.address.postalCode || this.serviceForm.value.address.postalCode == 'undefined')
      return this.cos.openSnackBar({ message: 'Please select a valid address or postal code' });
    const data = {
      address: this.serviceForm.value.address,
      serviceAreaId: this.serviceForm.value.serviceAreaId,
      deliveryCharge: {
        totalAmount: this.serviceForm.value.deliveryCharge, units: 'AUD'
      }
    };

    this.srvcArea.putServiceArea(data)
      .then((res: any) => {
        if (res.status) {
          this.cos.openSnackBar({ message: res.message });
          this.getServiceArea();
          $('#addservicearea').modal('hide');
        } else {
          this.cos.openSnackBar({ message: res.message });
        }
      })
      .catch(err => {
        this.cos.debugMail(err);
        this.cos.openSnackBar({ message: err.message });
      });

  }

  getAddress(event) {
    this.agmObj = {
      lat: event.geo[0],
      lng: event.geo[1]
    };
    this.serviceForm.patchValue({
      address: {
        streetAddress: event.route ? event.route : 'undefined',
        locality: event.locality ? event.locality : 'undefined',
        subLocality: event.sub_locality ? event.sub_locality : 'undefined',
        region: event.country,
        country: event.country,
        postalCode: event.postal_code ? event.postal_code : 'undefined',
        geo: [event.geo[1], event.geo[0]],
        formattedAddress: event.formattedAddress,
        landmark: event.formattedAddress
      },
      shortAddress: event.formattedAddress
    });
  }

  // async bulkDelete() {
  //   const userIds = await this.selection.selected.map(selec => selec._id);
  //   if (userIds.length === 0) {
  //     this.cos.openSnackBar({ message: 'Please Select agent to delete !' });
  //     return;
  //   }
  //   this.deleteIDs = userIds;
  //   this.deleteUser();
  // }

  // async singleDelete(ids) {
  //   await this.deleteIDs.push(ids);
  //   this.deleteUser();
  // }

  deleteUser(id) {

    swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.srvcArea.delete({ ids: [id] })
          .then((res: any) => {
            this.cos.openSnackBar({ message: res.message });
            this.getServiceArea();
          })
          .catch(err => {
            this.cos.debugMail(err);
            this.cos.openSnackBar({ message: err.message });
          });
      }
    });
  }

}

