import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from './../common.service';
import { FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, PageEvent } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { ServicesService } from './services.service';
import Swal from 'sweetalert2';
declare var $: any;
const ELEMENT_DATA: PeriodicElement[] = [];

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private cos: CommonService,
    private spinner: NgxSpinnerService,
    private srvcSrvc: ServicesService) { }

  modalType = 'Add Service';
  deleteIDs = new Array();
  allItems: any[];
  pagedItems: any[] = [];

  pager = {
    totalItems: 0,
    currentPage: 1,
    pageSize: 10,
    totalPages: 10,
    startPage: 1,
    endPage: 10,
    startIndex: 1,
    endIndex: 10,
    pages: []
  };
  searchObj = {
    search: '',
    pageNumber: 1,
    noOfRecord: 10
  };
  rightModalData = {
    serviceDetail: {} as PeriodicElement
  };

  categoryImage: any;
  validForm: boolean;

  servicesForm = this.fb.group({
    serviceId: [''],
    serviceName: ['', [Validators.required]],
    ETA: ['', [Validators.required]]
  });

  typing: any = null;

  length = 100;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25, 100];

  pageEvent: PageEvent;

  displayedColumns: string[] = ['select', 'name', 'expectedETA', 'action'];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  selection = new SelectionModel<PeriodicElement>(true, []);
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }
  applyFilter(filterValue: string) {
    const value = filterValue.trim().toLowerCase();
    clearInterval(this.typing);
    this.searchObj.search = value;
    this.searchObj.pageNumber = 1;
    this.typing = setTimeout(() => this.getServices(), 500);
  }
  fltrtoggle() {
    $('#fltrdown').toggle();
  }

  ngOnInit() {
    this.dataSource.sort = this.sort;
    this.getServices();
  }

  resetForm() {
    this.modalType = 'Add Service';
    this.servicesForm.reset();
    this.validForm = false;
  }

  setPage(page: PageEvent): PageEvent {
    this.searchObj.pageNumber = Number(page.pageIndex + 1);
    this.searchObj.noOfRecord = Number(page.pageSize);
    this.getServices();
    return;
  }


  getServices() {
    this.spinner.show();
    this.srvcSrvc.getService(this.searchObj)
      .then((res: PaginationRes) => {
        if (!res.status) {
          this.cos.openSnackBar({ message: res.message });
        }
        this.dataSource.data = res.data;
        this.pager = this.cos.getPager(Number(res.totalRecords), Number(res.currentPage));
        this.length = this.pager.totalItems;
        this.pageSize = this.pager.pageSize;
      })
      .then(() => this.spinner.hide())
      .catch(err => {
        this.spinner.hide();
        this.cos.openSnackBar({ message: err.message });
      });
  }

  submitService() {
    if (this.servicesForm.value.serviceId) {
      this.editService();
    } else {
      this.addService();
    }
  }

  addService() {
    if (this.servicesForm.invalid) { return; }

    const data = {
      serviceName: this.servicesForm.value.serviceName,
      ETA: this.servicesForm.value.ETA
    };

    this.srvcSrvc.postService(data)
      .then((res: DefaultRes) => {
        if (res.status) {
          this.cos.openSnackBar({ message: res.message });
          this.resetForm();
          $('#addservice').modal('hide');
          this.getServices();
        } else {
          this.cos.openSnackBar({ message: res.message });
        }
      })
      .catch(err => {
        this.cos.openSnackBar({ message: err.message });
      });
  }

  editService() {
    if (this.servicesForm.invalid) { return; }

    const data = {
      serviceId: this.servicesForm.value.serviceId,
      serviceName: this.servicesForm.value.serviceName,
      ETA: this.servicesForm.value.ETA

    };

    this.srvcSrvc.putService(data)
      .then((res: DefaultRes) => {
        if (res.status) {
          this.cos.openSnackBar({ message: res.message });
          this.getServices();
          this.resetForm();
          $('#addservice').modal('hide');
        } else {
          this.cos.openSnackBar({ message: res.message });
        }
      })
      .catch(err => {
        this.cos.openSnackBar({ message: err.message });
      });
  }
  async bulkDelete() {
    const userIds = await this.selection.selected.map(selec => selec._id);
    if (userIds.length === 0) {
      this.cos.openSnackBar({ message: 'Please Select Service to delete !' });
      return;
    }
    this.deleteIDs = userIds;
    this.deleteUser();
  }

  async singleDelete(ids) {
    await this.deleteIDs.push(ids);
    this.deleteUser();
  }

  deleteUser() {

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.srvcSrvc.delete({ ids: this.deleteIDs })
          .then((res: DefaultRes) => {
            this.cos.openSnackBar({ message: res.message });
            this.dataSource.data = this.dataSource.data.filter(obj => !new Set(this.deleteIDs).has(obj._id));
          })
          .catch(err => {
            this.cos.openSnackBar({ message: err.message });
          });
      }
    });

  }

  rightmodal(data: PeriodicElement) {
    this.rightModalData.serviceDetail = data;
  }

  setValueToModal(elem) {
    this.modalType = 'Edit Service';
    this.servicesForm.patchValue({
      serviceId: elem._id,
      serviceName: elem.serviceName,
      ETA: elem.ETA
    });
  }

}
export interface PeriodicElement {
  status: boolean;
  ETA: Date;
  isDeleted: boolean;
  _id: string;
  serviceName: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface PaginationRes {
  currentPage: number;
  data: [PeriodicElement];
  message: string;
  status: boolean;
  totalPage: number;
  totalRecords: number;
}

export interface DefaultRes {
  data: null | PeriodicElement;
  message: string;
  status: boolean;
}

export interface Upload {
  data: string;
  message: string;
  status: boolean;
}
