import { environment } from './../../environments/environment';
import { CommonService } from './../common.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {

  CATEGORY = 'inventory/category';

  constructor(private cos: CommonService) { }

  getCategory(obj) {
    return new Promise((resolve, reject) => {

      this.cos.httpRequest(`${this.CATEGORY}?search=` + obj.search + `&page=${obj.pageNumber}&limit=${obj.noOfRecord}&type=${obj.type}`)
        .then(resolve)
        .catch(reject);
    });
  }

  postCategory(data) {
    return new Promise((resolve, reject) => {
      this.cos.httpRequest(this.CATEGORY, data, 'POST')
        .then(resolve)
        .catch(reject);
    });
  }

  putUser(data) {
    return new Promise((resolve, reject) => {
      this.cos.httpRequest(this.CATEGORY, data, 'PUT')
        .then(resolve)
        .catch(reject);
    });
  }

  delete(data) {
    return new Promise((resolve, reject) => {
      this.cos.httpRequest(this.CATEGORY, data, 'DELETE')
        .then(resolve)
        .catch(reject);
    });
  }

  uploadImage(data) {
    return new Promise((resolve, reject) => {
      this.cos.httpFormRequest(environment.UPLOAD, data)
        .then(resolve)
        .catch(reject);
    });
  }
}
