import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, Validators } from '@angular/forms';
import { CommonService } from './../common.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, PageEvent } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { PromocodeService } from './promocode.service';
import Swal from 'sweetalert2';
declare var $: any;
const ELEMENT_DATA: PeriodicElement[] = [];

@Component({
  selector: 'app-promocode',
  templateUrl: './promocode.component.html',
  styleUrls: ['./promocode.component.css']
})

export class PromocodeComponent implements OnInit {

  typing: any = null;

  pager = {
    totalItems: 0,
    currentPage: 1,
    totalPages: 10,
    startPage: 1,
    pageSize: 10,
    endPage: 10,
    startIndex: 1,
    endIndex: 10,
    pages: []
  };

  length = 100;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  searchObj = {
    search: '',
    pageNumber: 1,
    noOfRecord: 10
  };
  modalType = 'Add Promo Code';

  promoOptions = {
    flatFlag: false,
    percentageFlag: true,
    referrallFlag: true,
  };

  rightModalData = {
    promoDetail: {} as PeriodicElement,
  };

  constructor(
    private promoSrvc: PromocodeService,
    private cos: CommonService,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder) { }
  promoForm = this.fb.group({
    id: [''],
    promoCode: ['', [Validators.required]],
    validThrough: ['', [Validators.required]],
    type: ['FLATOFF'],
    flatDiscount: ['', [Validators.required]],
    flatAmount: ['', [Validators.required]],
    percentageDiscount: [''],
    percentageAmount: [''],
    referalDiscount: [''],
    referalAmount: [''],
    // noOfUsage: ['', [Validators.required]],
    description: ['', [Validators.required]],
    termsAndCondition: ['', [Validators.required]]
  });

  pageEvent: PageEvent;

  displayedColumns: string[] = ['select', 'promoCode', 'expireDate', 'benifit', 'termCondition', 'status', 'action'];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  selection = new SelectionModel<PeriodicElement>(true, []);
  deleteIDs = new Array();

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  applyFilter(filterValue: string) {
    const value = filterValue.trim().toLowerCase();
    clearInterval(this.typing);
    this.searchObj.search = value;
    this.searchObj.pageNumber = 1;
    this.typing = setTimeout(() => this.getData(), 500);
  }

  fltrtoggle() {
    $('#fltrdown').toggle();
  }

  resetForm() {
    this.modalType = 'Add Promo Code';
    this.promoForm.reset();
  }

  ngOnInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.getData();
  }

  getData() {
    this.spinner.show();
    this.promoSrvc.getPromo(this.searchObj)
      .then((res: PaginationRes) => {
        if (!res.status) {
          this.cos.openSnackBar({ message: res.message });
        }
        this.dataSource.data = res.data;
        this.pager = this.cos.getPager(Number(res.totalRecords), Number(res.currentPage));
        this.length = this.pager.totalItems;
        this.pageSize = this.pager.pageSize;
      })
      .then(() => this.spinner.hide())
      .catch(err => {
        this.spinner.hide();
        this.cos.openSnackBar({ message: err.message });
      });
  }

  setPage(page: PageEvent): PageEvent {
    this.searchObj.pageNumber = Number(page.pageIndex + 1);
    this.searchObj.noOfRecord = Number(page.pageSize);
    this.getData();
    return;
  }

  promoType(type) {

    if (type === 'FLATOFF') {
      this.promoOptions.flatFlag = false;
      this.promoOptions.percentageFlag = true;
      this.promoOptions.referrallFlag = true;

      this.addValidation(['flatDiscount', 'flatAmount']);
      this.updateValidation(['percentageDiscount', 'percentageAmount', 'referalDiscount', 'referalAmount']);

    } else if (type === 'PERCENTAGE') {
      this.promoOptions.flatFlag = true;
      this.promoOptions.percentageFlag = false;
      this.promoOptions.referrallFlag = true;
      this.addValidation(['percentageDiscount', 'percentageAmount']);
      this.updateValidation(['flatDiscount', 'flatAmount', 'referalDiscount', 'referalAmount']);

    } else if (type === 'REFERRAL') {
      this.promoOptions.flatFlag = true;
      this.promoOptions.percentageFlag = true;
      this.promoOptions.referrallFlag = false;
      this.addValidation(['referalDiscount', 'referalAmount']);
      this.updateValidation(['flatDiscount', 'flatAmount', 'percentageDiscount', 'percentageAmount']);
    }
  }

  addValidation(array) {
    array.forEach(element => {
      this.promoForm.controls[element].setValidators([Validators.required]);
      this.promoForm.controls[element].updateValueAndValidity();
    });
  }

  updateValidation(array) {
    array.forEach(element => {
      this.promoForm.controls[element].clearValidators();
      this.promoForm.controls[element].updateValueAndValidity();
    });
  }

  submitPromo() {
    if (this.promoForm.value.id) {
      this.editPromo();
    } else {
      this.addPromo();
    }
  }

  addPromo() {
    let promo = {};
    if (this.promoForm.value.type === 'FLATOFF') {
      promo = {
        promoType: this.promoForm.value.type,
        flatAmount: this.promoForm.value.flatDiscount,
        minAmountRequired: this.promoForm.value.flatAmount,
      };
    } else if (this.promoForm.value.type === 'REFERRAL') {
      promo = {
        promoType: this.promoForm.value.type,
        flatOffForReferral: this.promoForm.value.referalDiscount,
        minAmountRequired: this.promoForm.value.referalAmount,
      };
    } else {
      promo = {
        promoType: this.promoForm.value.type,
        percentage: this.promoForm.value.percentageDiscount,
        maxAmount: this.promoForm.value.percentageAmount,
      };
    }

    const data = {
      name: this.promoForm.value.promoCode,
      description: this.promoForm.value.description,
      status: true,
      validThrough: this.promoForm.value.validThrough,
      type: promo,
      // maxUsesPerUser: this.promoForm.value.noOfUsage,
      termsAndCondition: this.promoForm.value.termsAndCondition
    };

    this.promoSrvc.postPromo(data)
      .then((res: DefaultRes) => {
        if (res.status) {
          this.cos.openSnackBar({ message: res.message });
          this.getData();
          this.resetForm();
          $('#addpromo').modal('hide');
        } else {
          this.cos.openSnackBar({ message: res.message });
        }
      })
      .catch((err) => this.cos.debugMail(err));
  }

  editPromo() {
    let promo = {};
    if (this.promoForm.value.type === 'FLATOFF') {
      promo = {
        promoType: this.promoForm.value.type,
        flatAmount: this.promoForm.value.flatDiscount,
        minAmountRequired: this.promoForm.value.flatAmount,
      };
    } else if (this.promoForm.value.type === 'REFERRAL') {
      promo = {
        promoType: this.promoForm.value.type,
        flatOffForReferral: this.promoForm.value.referalDiscount,
        minAmountRequired: this.promoForm.value.referalAmount,
      };
    } else {
      promo = {
        promoType: this.promoForm.value.type,
        percentage: this.promoForm.value.percentageDiscount,
        maxAmount: this.promoForm.value.percentageAmount,
      };
    }

    const data = {
      id: this.promoForm.value.id,
      name: this.promoForm.value.promoCode,
      description: this.promoForm.value.description,
      status: true,
      validThrough: this.promoForm.value.validThrough,
      type: promo,
      // maxUsesPerUser: this.promoForm.value.noOfUsage,
      termsAndCondition: this.promoForm.value.termsAndCondition
    };

    this.promoSrvc.putPromo(data)
      .then((res: DefaultRes) => {
        if (res.status) {
          this.cos.openSnackBar({ message: res.message });
          this.getData();
          this.resetForm();
          $('#addpromo').modal('hide');
        } else {
          this.cos.openSnackBar({ message: res.message });
        }
      })
      .catch((err) => this.cos.debugMail(err));
  }

  setValueToModal(elem) {
    this.modalType = 'Edit Promo Code';
    this.promoForm.patchValue({
      id: elem._id,
      promoCode: elem.name,
      validThrough: elem.validThrough,
      // noOfUsage: elem.maxUsesPerUser,
      description: elem.description,
      termsAndCondition: elem.termsAndCondition,

      type: elem.type.promoType,
    });

    if (elem.type.promoType === 'FLATOFF') {
      this.promoForm.patchValue({
        flatDiscount: elem.type.flatAmount,
        flatAmount: elem.type.minAmountRequired,
      });
    } else if (elem.type.promoType === 'PERCENTAGE') {
      this.promoForm.patchValue({
        percentageDiscount: elem.type.percentage,
        percentageAmount: elem.type.maxAmount,
      });
    } else {
      this.promoForm.patchValue({
        referalDiscount: elem.type.flatOffForReferral,
        referalAmount: elem.type.minAmountRequired,
      });
    }
    this.promoType(elem.type.promoType);
  }

  blockUnblock(data) {
    data.id = data._id;
    data.status = !data.status;
    const bundle = this.cos.deleteProp(['_id', '__v', 'createdAt', 'updatedAt', 'isDeleted'], data);

    this.promoSrvc.putPromo(bundle)
      .then((res: DefaultRes) => {
        if (res.status) {
          res.data.status ? this.cos.openSnackBar({ message: 'Promo Code Active Successfully' }) :
            this.cos.openSnackBar({ message: 'Promo Code Inactive Successfully' });
          const objectID = this.dataSource.data.findIndex(x => x._id === res.data._id);
          this.dataSource.data[objectID].status = bundle.status;
        } else {
          this.cos.openSnackBar({ message: res.message });
        }
      })
      .catch(err => {
        this.cos.openSnackBar({ message: err.message });
      });
  }

  async bulkDelete() {
    const userIds = await this.selection.selected.map(selec => selec._id);
    if (userIds.length === 0) {
      this.cos.openSnackBar({ message: 'Please Select Promo to delete !' });
      return;
    }
    this.deleteIDs = userIds;
    this.deleteUser();
  }

  async singleDelete(ids) {
    await this.deleteIDs.push(ids);
    this.deleteUser();
  }

  deleteUser() {

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.promoSrvc.delete({ ids: this.deleteIDs })
          .then((res: DefaultRes) => {
            this.cos.openSnackBar({ message: res.message });
            this.dataSource.data = this.dataSource.data.filter(obj => !new Set(this.deleteIDs).has(obj._id));
          })
          .catch(err => {
            this.cos.openSnackBar({ message: err.message });
          });
      }
    });
  }

  rightModal(data: PeriodicElement) {
    this.rightModalData.promoDetail = data;
  }
}

export interface PeriodicElement {

  maxUsesPerUser: boolean;
  description: string;
  isDeleted: boolean;
  name: boolean;
  status: boolean;
  userType: string;
  _id: string;
  type: {
    promoType: string;
    flatAmount: number;
    minAmountRequired: number;
    maxAmount: number;
    flatOffForReferral: number;
    percentage: number;
  };
  termsAndCondition: string;
  createdAt: Date;
  validThrough: Date;
}

export interface PaginationRes {
  currentPage: number;
  data: [PeriodicElement];
  message: string;
  status: boolean;
  totalPage: number;
  totalRecords: number;
}

export interface DefaultRes {
  data: null | PeriodicElement;
  message: string;
  status: boolean;
}
