import { CommonService } from '../common.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class ServiceAreaService {

  SERVICE_AREA = 'inventory/serviceArea';

  constructor(private commonSrvc: CommonService) { }

  getServiceArea(obj) {
    return new Promise((resolve, reject) => {
      this.commonSrvc.httpRequest(`${this.SERVICE_AREA}?search=${obj.search}&page=${obj.pageNumber}&limit=${obj.noOfRecord}&type=${obj.type}`)
        .then(resolve)
        .catch(reject);
    });
  }

  putServiceArea(data) {
    return new Promise((resolve, reject) => {
      this.commonSrvc.httpRequest(this.SERVICE_AREA, data, 'PUT')
        .then(resolve)
        .catch(reject);
    });
  }

  postServiceArea(data) {
    return new Promise((resolve, reject) => {
      this.commonSrvc.httpRequest(this.SERVICE_AREA, data, 'POST')
        .then(resolve)
        .catch(reject);
    });
  }

  delete(data) {
    return new Promise((resolve, reject) => {
      this.commonSrvc.httpRequest(this.SERVICE_AREA, data, 'DELETE')
        .then(resolve)
        .catch(reject);
    });
  }

}
