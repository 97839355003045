import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from './../common.service';
import { FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, PageEvent } from '@angular/material';
import { MatTable } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { CategoriesService } from './categories.service';
import Swal from 'sweetalert2';
declare var $: any;

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css']
})
export class CategoriesComponent implements OnInit {

  modalType = 'Add Category';
  deleteIDs = new Array();
  allItems: any[];
  pagedItems: any[] = [];
  categoryData: any[] = [];
  pager = {
    totalItems: 0,
    currentPage: 1,
    pageSize: 10,
    totalPages: 10,
    startPage: 1,
    endPage: 10,
    startIndex: 1,
    endIndex: 10,
    pages: []
  };
  searchObj = {
    type: 'ONE',
    search: '',
    pageNumber: 1,
    noOfRecord: 10
  };
  rightModalData = {
    categoryDetail: {} as PeriodicElement
  };

  categoryImage: any;
  validForm: boolean;

  categoryForm = this.fb.group({
    categoryId: [''],
    categoryName: ['', [Validators.required]],
    image: ['']
  });
  loader = false;

  typing: any = null;

  length = 100;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25, 100];

  constructor(
    private fb: FormBuilder,
    private cos: CommonService,
    private spinner: NgxSpinnerService,
    private catSrvc: CategoriesService) { }

  pageEvent: PageEvent;
  displayedColumns: string[] = ['select', 'name', 'image', 'action'];
  selection = new SelectionModel<PeriodicElement>(true, []);

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('table', { static: false }) table: MatTable<PeriodicElement>;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild('uploadImage', { static: false }) uploadImage;

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
  }

  fltrtoggle() {
    $('#fltrdown').toggle();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */

  applyFilter(filterValue: string) {
    const value = filterValue.trim().toLowerCase();
    clearInterval(this.typing);
    this.searchObj.search = value;
    this.searchObj.pageNumber = 1;
    this.typing = setTimeout(() => this.getCategory(), 500);
  }

  ngOnInit() {
    this.getCategory();
  }

  resetForm() {
    this.modalType = 'Add Category';
    this.categoryForm.reset();
    this.validForm = false;
    this.uploadImage.nativeElement.value = '';
  }

  getCategory() {
    this.spinner.show();

    this.catSrvc.getCategory(this.searchObj)
      .then((res: PaginationRes) => {
        if (!res.status) {
          this.cos.openSnackBar({ message: res.message });
        }
        // this.dataSource.data = res.data;
        this.categoryData = res.data;
        this.pager = this.cos.getPager(Number(res.totalRecords), Number(res.currentPage));
        this.length = this.pager.totalItems;
        this.pageSize = this.pager.pageSize;
      })
      .then(() => this.spinner.hide())
      .catch(err => {
        this.spinner.hide();
        this.cos.openSnackBar({ message: err.message });
        this.cos.debugMail(err);
      });
  }

  setPage(page: PageEvent): PageEvent {
    this.searchObj.pageNumber = Number(page.pageIndex + 1);
    this.searchObj.noOfRecord = Number(page.pageSize);
    this.getCategory();
    return;
  }

  submitCategory() {
    if (this.categoryForm.value.categoryId) {
      this.editCategory();
    } else {
      this.addCategory();
    }
  }

  uploadProfileImg() {

    this.loader = true;
    const fi = this.uploadImage.nativeElement;
    const imageData = new FormData();
    this.validForm = false;
    if (fi.files && fi.files[0]) {

      imageData.append('file', fi.files[0]); // data to upload ...
      const reader = new FileReader(); // data to display ...
      reader.readAsDataURL(fi.files[0]);
      reader.onload = () => {
        this.categoryImage = reader.result;
      };
    }

    this.catSrvc.uploadImage(imageData)
      .then((res: Upload) => {
        if (!res.status) { return; }
        this.categoryForm.patchValue({
          image: res.data
        });
        this.validForm = true;
        this.loader = false;
      })
      .catch(() => this.loader = false);
  }

  addCategory() {
    if (this.categoryForm.invalid) { return; }

    delete this.categoryForm.value.categoryId;

    this.catSrvc.postCategory(this.categoryForm.value)
      .then((res: DefaultRes) => {
        if (res.status) {
          this.cos.openSnackBar({ message: res.message });
          this.resetForm();
          $('#addcategory').modal('hide');
          this.getCategory();
        } else {
          this.cos.openSnackBar({ message: res.message });
        }
      })
      .catch(err => {
        this.cos.openSnackBar({ message: err.message });
        this.cos.debugMail(err);
      });
  }

  editCategory() {
    if (this.categoryForm.invalid) { return; }

    this.catSrvc.putUser(this.categoryForm.value)
      .then((res: DefaultRes) => {
        if (res.status) {
          this.cos.openSnackBar({ message: res.message });
          this.getCategory();
          this.resetForm();
          $('#addcategory').modal('hide');
        } else {
          this.cos.openSnackBar({ message: res.message });
        }
      })
      .catch(err => {
        this.cos.openSnackBar({ message: err.message });
        this.cos.debugMail(err);
      });
  }

  async bulkDelete() {
    const userIds = await this.selection.selected.map(selec => selec._id);
    if (userIds.length === 0) {
      this.cos.openSnackBar({ message: 'Please Select user to delete !' });
      return;
    }
    this.deleteIDs = userIds;
    this.deleteUser();
  }

  async singleDelete(ids) {
    await this.deleteIDs.push(ids);
    this.deleteUser();
  }

  deleteUser() {

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {

        this.catSrvc.delete({ ids: this.deleteIDs })
          .then((res: DefaultRes) => {
            this.cos.openSnackBar({ message: res.message });
            this.categoryData = this.categoryData.filter(obj => !new Set(this.deleteIDs).has(obj._id));
          })
          .catch(err => {
            this.cos.openSnackBar({ message: err.message });
            this.cos.debugMail(err);
          });
      }
    });

  }

  rightmodal(data: PeriodicElement) {
    this.rightModalData.categoryDetail = data;
  }

  setValueToModal(elem) {
    this.modalType = 'Edit Category';
    this.categoryForm.patchValue({
      categoryId: elem._id,
      categoryName: elem.categoryName,
      image: elem.image
    });

    elem.image ? this.validForm = true : this.validForm = false;
  }


}
export interface PeriodicElement {
  categoryName: string;
  createdAt: Date;
  image: string;
  isDeleted: boolean;
  status: boolean;
  updatedAt: Date;
  _id: string;
}

export interface PaginationRes {
  currentPage: number;
  data: [PeriodicElement];
  message: string;
  status: boolean;
  totalPage: number;
  totalRecords: number;
}

export interface DefaultRes {
  data: null | PeriodicElement;
  message: string;
  status: boolean;
}

export interface Upload {
  data: string;
  message: string;
  status: boolean;
}
