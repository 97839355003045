import { CommonService } from './../common.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  SETTINGS = 'settings/setting';
  CHARGE = 'inventory/charge';

  constructor(private cos: CommonService) { }

  getSetting() {
    return new Promise((resolve, reject) => {
      this.cos.httpRequest(this.SETTINGS)
        .then(resolve)
        .catch(reject);
    });
  }

  postSetting(data) {
    return new Promise((resolve, reject) => {
      this.cos.httpRequest(this.SETTINGS, data, 'POST')
        .then(resolve)
        .catch(reject);
    });
  }

  putSetting(data) {
    return new Promise((resolve, reject) => {
      this.cos.httpRequest(this.SETTINGS, data, 'PUT')
        .then(resolve)
        .catch(reject);
    });
  }

  deleteSetting(data) {
    return new Promise((resolve, reject) => {
      this.cos.httpRequest(this.SETTINGS, data, 'DELETE')
        .then(resolve)
        .catch(reject);
    });
  }

  postCharge(data) {
    return new Promise((resolve, reject) => {
      this.cos.httpRequest(this.CHARGE, data, 'POST')
        .then(resolve)
        .catch(reject);
    });
  }

  getCharge() {
    return new Promise((resolve, reject) => {
      this.cos.httpRequest(this.CHARGE)
        .then(resolve)
        .catch(reject);
    });
  }
}
