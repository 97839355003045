import { NgxSpinnerService } from 'ngx-spinner';
import { Upload } from './../profile/profile.component';
import { ProfileService } from './../profile/profile.service';
import { CommonService } from './../common.service';
import { UserService } from './user.service';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, PageEvent } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
declare var $: any;

const ELEMENT_DATA: PeriodicElement[] = [];

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  customerdata: any;
  hide = true;
  modalType = 'Add Customer';
  deleteIDs = new Array();
  allItems: any[];
  pagedItems: any[] = [];
  @Input() diameter = 150;
  @Input() strokeWidth = 5;

  showSpinner = false;
  pager = {
    totalItems: 0,
    currentPage: 1,
    pageSize: 10,
    totalPages: 10,
    startPage: 1,
    endPage: 10,
    startIndex: 1,
    endIndex: 10,
    pages: []
  };
  searchObj = {
    type: 'USER',
    search: '',
    pageNumber: 1,
    noOfRecord: 10
  };

  displayedColumns: string[] = ['select', 'name', 'email', 'customerType', 'phone',
    'profile_image', 'order_details', 'status', 'action'];
  rightModalData = {
    customerDetail: {} as PeriodicElement,
    promoDetail: {}
  };
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  selection = new SelectionModel<PeriodicElement>(true, []);

  length = 100;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25, 100];

  pageEvent: PageEvent;

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  @ViewChild('uploadImage', { static: false }) uploadImage;

  constructor(
    private profileSrvc: ProfileService,
    private usrSrvc: UserService,
    private cos: CommonService,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder) {
    this.getUsers();
  }

  userForm = this.fb.group({
    id: [''],
    userName: ['', [Validators.required]],
    email: ['', [Validators.required]],
    phone: ['', [Validators.required]],
    picture: [''],
    userType: ['', [Validators.required]]
  });

  profilePic: any;
  validForm: boolean;
  typing: any = null;


  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  applyFilter(filterValue: string) {
    const value = filterValue.trim().toLowerCase();
    clearInterval(this.typing);
    this.searchObj.search = value;
    this.searchObj.pageNumber = 1;
    this.typing = setTimeout(() => this.getUsers(), 500);
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
  }

  ngOnInit() {
    this.dataSource.sort = this.sort;
  }

  resetForm() {
    this.modalType = 'Add Customer';
    this.userForm.reset();
    this.validForm = false;

  }

  setPage(page: PageEvent): PageEvent {
    this.searchObj.pageNumber = Number(page.pageIndex + 1);
    this.searchObj.noOfRecord = Number(page.pageSize);
    this.getUsers();
    return;
  }

  uploadProfileImg() {
    this.showSpinner = true;
    const fi = this.uploadImage.nativeElement;
    const imageData = new FormData();

    if (fi.files && fi.files[0] && fi.files[0].type.includes('image')) {

      imageData.append('file', fi.files[0]); // data to upload ...

      const reader = new FileReader(); // data to display ...
      reader.readAsDataURL(fi.files[0]);
      reader.onload = () => {
        this.profilePic = reader.result;
      };
    } else {
      this.cos.openSnackBar({ message: 'Only Type Images can be uploaded' });
      this.showSpinner = false;
    }

    this.profileSrvc.uploadImage(imageData)
      .then((res: Upload) => {
        if (!res.status) { return; }
        this.userForm.patchValue({
          picture: res.data
        });
        this.validForm = true;
        this.showSpinner = false;
      })
      .catch();
  }

  getUsers() {
    this.spinner.show();
    this.usrSrvc.getUser(this.searchObj)
      .then((res: PaginationRes) => {
        if (res.status) {
          this.dataSource.data = res.data;
          this.pager = this.cos.getPager(Number(res.totalRecords), Number(res.currentPage));
          this.length = this.pager.totalItems;
          this.pageSize = this.pager.pageSize;
        } else {
          this.cos.openSnackBar({ message: res.message });
        }
      })
      .then(() => this.spinner.hide())
      .catch(err => {
        this.spinner.hide();
        this.cos.openSnackBar({ message: err.message });
      });
  }

  addUser() {
    if (this.userForm.invalid) { return; }

    const data = {
      picture: this.userForm.value.picture,
      auth: {
        email: this.userForm.value.email,
        passKey: this.cos.genPassword(8)
      },
      name: {
        userName: this.userForm.value.userName,
      },
      contact: {
        email: this.userForm.value.email,
        phone: this.userForm.value.phone
      },
      userType: this.userForm.value.userType,
      deviceId: 'ADMIN',
      fcmToken: 'ADMIN'
    };

    this.usrSrvc.postUser(data)
      .then((res: DefaultRes) => {
        if (res.status) {
          this.cos.openSnackBar({ message: res.message });
          this.resetForm();
          $('#adduser').modal('hide');
          this.getUsers();
        } else {
          this.cos.openSnackBar({ message: res.message });
        }
      })
      .catch(err => {
        this.cos.openSnackBar({ message: err.message });
      });
  }

  editUser() {
    if (this.userForm.invalid) { return; }

    const data = {
      picture: this.userForm.value.picture,
      name: {
        userName: this.userForm.value.userName
      },
      contact: {
        email: this.userForm.value.email,
        phone: this.userForm.value.phone
      },
      userType: this.userForm.value.userType,
      userId: this.userForm.value.id,
    };

    this.usrSrvc.putUser(data)
      .then((res: DefaultRes) => {
        if (res.status) {
          this.cos.openSnackBar({ message: res.message });
          this.getUsers();
          this.resetForm();
          $('#adduser').modal('hide');
        } else {
          this.cos.openSnackBar({ message: res.message });
        }
      })
      .catch(err => {
        this.cos.openSnackBar({ message: err.message });
      });
  }

  submitUser() {
    if (this.userForm.value.id) {
      this.editUser();
    } else {
      this.addUser();
    }
  }

  setValueToModal(elem) {
    this.modalType = 'Edit Customer';
    this.userForm.patchValue({
      id: elem._id,
      userName: elem.name.userName,
      email: elem.contact.email,
      phone: elem.contact.phone,
      picture: elem.picture,
      userType: elem.userType
    });

    elem.picture ? this.validForm = true : this.validForm = false;
  }

  blockUnblock(data) {

    this.usrSrvc.blockUnblock(data)
      .then((res: DefaultRes) => {
        if (res.status) {

          data.isActive ? this.cos.openSnackBar({ message: 'User Unblocked Successfully' }) :
            this.cos.openSnackBar({ message: 'User Blocked Successfully' });
          const object = this.dataSource.data.findIndex(x => x._id === data.userId);
          this.dataSource.data[object].isActive = data.isActive;
        } else {
          this.cos.openSnackBar({ message: res.message });
        }
      })
      .catch(err => {
        this.cos.openSnackBar({ message: err.message });
      });
  }

  async bulkDelete() {
    const userIds = await this.selection.selected.map(selec => selec._id);
    if (userIds.length === 0) {
      this.cos.openSnackBar({ message: 'Please Select customer to delete !' });
      return;
    }
    this.deleteIDs = userIds;
    this.deleteUser();
  }

  async singleDelete(ids) {
    await this.deleteIDs.push(ids);
    this.deleteUser();
  }

  deleteUser() {


    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.usrSrvc.delete({ ids: this.deleteIDs })
          .then((res: DefaultRes) => {
            this.cos.openSnackBar({ message: res.message });
            this.dataSource.data = this.dataSource.data.filter(obj => !new Set(this.deleteIDs).has(obj._id));
          })
          .catch(err => {
            this.cos.openSnackBar({ message: err.message });
          });
      }
    });

  }

  rightmodal(data: PeriodicElement) {
    this.rightModalData.customerDetail = data;
  }

}

export interface PeriodicElement {
  picture: string;
  verification: boolean;
  description: string;
  isAvailable: boolean;
  isDeleted: boolean;
  isActive: boolean;
  isApproved: boolean;
  userType: string;
  _id: string;
  address: [{}];
  contact: {
    email: string;
    phone: number;
  };
  name: {
    firstName: string;
    lastName: string;
    userName: string;
  };
  transport: {
    transportColor: string;
    transportDesc: string;
    transportNo: string;
    transportType: string;
  };
  role: string;
  createdAt: string;
  updatedAt: string;
}

export interface PaginationRes {
  currentPage: number;
  data: [PeriodicElement];
  message: string;
  status: boolean;
  totalPage: number;
  totalRecords: number;
}

export interface DefaultRes {
  data: null | PeriodicElement;
  message: string;
  status: boolean;
}
