import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { UsersComponent } from './users/users.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SidebarlayoutComponent } from './sidebarlayout/sidebarlayout.component';
import { HeaderComponent } from './common/header/header.component';
import { FooterComponent } from './common/footer/footer.component';
import { SidebarComponent } from './common/sidebar/sidebar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import {
  MatTableModule,
  MatPaginatorModule,
  MatButtonModule,
  MatSortModule,
  MatNativeDateModule,
  MatProgressSpinnerModule,
  MatProgressBarModule
} from '@angular/material';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { NgxEditorModule } from 'ngx-editor';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AgentsComponent } from './agents/agents.component';
import { ProductsComponent } from './products/products.component';
import { OrdersComponent } from './orders/orders.component';
import { PricingComponent } from './pricing/pricing.component';
import { PromocodeComponent } from './promocode/promocode.component';
import { ProfileComponent } from './profile/profile.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { CategoriesComponent } from './categories/categories.component';
import { ServicesComponent } from './services/services.component';
import { SettingsComponent } from './settings/settings.component';
import { CollectiondeliveryComponent } from './faq/collectiondelivery/collectiondelivery.component';
import { PaymentComponent } from './faq/payment/payment.component';
import { WashingComponent } from './faq/washing/washing.component';
import { AccountPasswordComponent } from './faq/account-password/account-password.component';
import { FaqSettingComponent } from './faq/faq-setting/faq-setting.component';
import { CancellationComponent } from './faq/cancellation/cancellation.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AgmDirectionModule } from 'agm-direction';

import { TestimonialComponent } from './website-content/testimonial/testimonial.component';
import { SocialLinkComponent } from './website-content/social-link/social-link.component';
import { HowItWorkComponent } from './website-content/how-it-work/how-it-work.component';
import { OurServicesComponent } from './website-content/our-services/our-services.component';
import { ServiceInfoComponent } from './website-content/service-info/service-info.component';
import { AboutUsFeaturesComponent } from './website-content/about-us-features/about-us-features.component';
import { AboutUsTitleComponent } from './website-content/about-us-title/about-us-title.component';
import { AboutCompanyComponent } from './website-content/about-company/about-company.component';
import { ReferralContentComponent } from './website-content/referral-content/referral-content.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { EnquiriesComponent } from './others/enquiries/enquiries.component';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { NgxSpinnerModule } from 'ngx-spinner';

import { AgmCoreModule } from '@agm/core';
import { AdminProfileComponent } from './admin-profile/admin-profile.component';
import { SendPromoComponent } from './send-promo/send-promo.component';
import { ReviewComponent } from './review/review.component';
import { RejectedOrderComponent } from './rejected-order/rejected-order.component';
import { GooglePlacesDirective } from './google-places.directive';
import { BannerComponent } from './banner/banner.component';
import { AddressComponent } from './address/address.component';
import { environment } from 'src/environments/environment';
import { ShortIdPipe } from './short-id.pipe';
import { ServiceAreaComponent } from './service-area/service-area.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    UsersComponent,
    SidebarlayoutComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    DashboardComponent,
    AgentsComponent,
    ProductsComponent,
    OrdersComponent,
    PricingComponent,
    PromocodeComponent,
    ProfileComponent,
    NotificationsComponent,
    CategoriesComponent,
    ServicesComponent,
    SettingsComponent,
    CollectiondeliveryComponent,
    PaymentComponent,
    WashingComponent,
    AccountPasswordComponent,
    FaqSettingComponent,
    CancellationComponent,
    TestimonialComponent,
    SocialLinkComponent,
    HowItWorkComponent,
    OurServicesComponent,
    ServiceInfoComponent,
    AboutUsFeaturesComponent,
    AboutUsTitleComponent,
    AboutCompanyComponent,
    ReferralContentComponent,
    ForgotpasswordComponent,
    EnquiriesComponent,
    OrderDetailsComponent,
    ResetPasswordComponent,
    AdminProfileComponent,
    SendPromoComponent,
    ReviewComponent,
    RejectedOrderComponent,
    GooglePlacesDirective,
    BannerComponent,
    AddressComponent,
    ShortIdPipe,
    ServiceAreaComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatSelectModule,
    DragDropModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatInputModule,
    MatRadioModule,
    HttpClientModule,
    NgxEditorModule,
    MatNativeDateModule,
    MatPaginatorModule, MatButtonModule, MatSortModule,
    PerfectScrollbarModule,
    ReactiveFormsModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatProgressBarModule,
    AgmCoreModule.forRoot({
      apiKey: environment.GOOGLE_KEY
    }),
    AgmDirectionModule,
    NgxSpinnerModule
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
