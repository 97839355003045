import { ReviewComponent } from './review/review.component';
import { AuthGuard } from './auth.guard';
import { AdminProfileComponent } from './admin-profile/admin-profile.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UsersComponent } from './users/users.component';
import { LoginComponent } from './login/login.component';
import { SidebarlayoutComponent } from './sidebarlayout/sidebarlayout.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AgentsComponent } from './agents/agents.component';
import { ProductsComponent } from './products/products.component';
import { OrdersComponent } from './orders/orders.component';
import { PricingComponent } from './pricing/pricing.component';
import { PromocodeComponent } from './promocode/promocode.component';
import { ProfileComponent } from './profile/profile.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { CategoriesComponent } from './categories/categories.component';
import { ServicesComponent } from './services/services.component';
import { SettingsComponent } from './settings/settings.component';

import { CollectiondeliveryComponent } from './faq/collectiondelivery/collectiondelivery.component';
import { PaymentComponent } from './faq/payment/payment.component';
import { WashingComponent } from './faq/washing/washing.component';
import { AccountPasswordComponent } from './faq/account-password/account-password.component';
import { FaqSettingComponent } from './faq/faq-setting/faq-setting.component';
import { CancellationComponent } from './faq/cancellation/cancellation.component';
import { TestimonialComponent } from './website-content/testimonial/testimonial.component';
import { SocialLinkComponent } from './website-content/social-link/social-link.component';
import { HowItWorkComponent } from './website-content/how-it-work/how-it-work.component';
import { OurServicesComponent } from './website-content/our-services/our-services.component';
import { ServiceInfoComponent } from './website-content/service-info/service-info.component';
import { AboutUsFeaturesComponent } from './website-content/about-us-features/about-us-features.component';
import { AboutUsTitleComponent } from './website-content/about-us-title/about-us-title.component';
import { AboutCompanyComponent } from './website-content/about-company/about-company.component';
import { ReferralContentComponent } from './website-content/referral-content/referral-content.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { EnquiriesComponent } from './others/enquiries/enquiries.component';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SendPromoComponent } from './send-promo/send-promo.component';
import { RejectedOrderComponent } from './rejected-order/rejected-order.component';
import { BannerComponent } from './banner/banner.component';
import { AddressComponent } from './address/address.component';
import { ServiceAreaComponent } from './service-area/service-area.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'forgotpassword', component: ForgotpasswordComponent },
  { path: 'reset-password', component: ResetPasswordComponent },

  {
    path: '', component: SidebarlayoutComponent, children: [
      { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
      { path: 'agents', component: AgentsComponent, canActivate: [AuthGuard] },
      { path: 'products', component: ProductsComponent, canActivate: [AuthGuard] },
      { path: 'orders', component: OrdersComponent, canActivate: [AuthGuard] },
      { path: 'rejected-order', component: RejectedOrderComponent, canActivate: [AuthGuard] },
      { path: 'pricing', component: PricingComponent, canActivate: [AuthGuard] },
      { path: 'promo-code', component: PromocodeComponent, canActivate: [AuthGuard] },
      { path: 'send-promo', component: SendPromoComponent, canActivate: [AuthGuard] },
      { path: 'users', component: UsersComponent, canActivate: [AuthGuard] },
      { path: 'profile-listing', component: ProfileComponent, canActivate: [AuthGuard] },
      { path: 'profile', component: AdminProfileComponent, canActivate: [AuthGuard] },
      { path: 'notifications', component: NotificationsComponent, canActivate: [AuthGuard] },
      { path: 'categories', component: CategoriesComponent, canActivate: [AuthGuard] },
      { path: 'services', component: ServicesComponent, canActivate: [AuthGuard] },
      { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard] },
      { path: 'reviews', component: ReviewComponent, canActivate: [AuthGuard] },
      { path: 'banner', component: BannerComponent, canActivate: [AuthGuard] },
      { path: 'shop', component: AddressComponent, canActivate: [AuthGuard] },

      { path: 'faq/collection-delivery', component: CollectiondeliveryComponent },
      { path: 'faq/payment', component: PaymentComponent },
      { path: 'faq/washing', component: WashingComponent },
      { path: 'faq/account-password', component: AccountPasswordComponent },
      { path: 'faq/setting', component: FaqSettingComponent },
      { path: 'faq/canellation', component: CancellationComponent },

      { path: 'website-content/testimonial', component: TestimonialComponent },
      { path: 'website-content/social-link', component: SocialLinkComponent },
      { path: 'website-content/how-it-work', component: HowItWorkComponent },
      { path: 'website-content/our-services', component: OurServicesComponent },
      { path: 'website-content/services-info', component: ServiceInfoComponent },
      { path: 'website-content/about-features', component: AboutUsFeaturesComponent },
      { path: 'website-content/about-title', component: AboutUsTitleComponent },
      { path: 'website-content/about-company', component: AboutCompanyComponent },
      { path: 'website-content/refferal-content', component: ReferralContentComponent },
      { path: 'others/enquiries', component: EnquiriesComponent },
      { path: 'order-details/:id/:type/:uType', component: OrderDetailsComponent },
      { path: 'service-area', component: ServiceAreaComponent },
    ]
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
