import { CommonService } from './../../common.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ChangePasswordService {

  CHANGE_PASSWORD = 'api/user/changePassword';

  constructor(private cos: CommonService) { }

  changePassword(data) {
    return new Promise((resolve, reject) => {
      this.cos.httpRequest(this.CHANGE_PASSWORD, data, 'PUT')
        .then(resolve)
        .catch(reject);
    });
  }
}
